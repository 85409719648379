import React, { useState } from "react";
import classnames from "classnames";
import { Row, Col, Collapse } from "reactstrap";
import _ from "lodash";

import {
  unknown,
  history,
  useQuery,
  momentLastUpdate,
} from "././../../../../utils";
import { Loading, RowItem, StatusBadge } from "./../../../../components";
import { useLocation } from "react-router";
import RestartLogModal from "./RestartLogModal";
import { useGetLastRestartHistoryQuery } from "features/device/service/usageApi";

const DeviceOverview = ({ device, getLanPortStatus }) => {
  const {
    sn,
    type,
    ipDevice,
    username,
    password,
    groups,
    area,
    region,
    customer,
    lanPorts,
    location,
    latitude,
    longitude,
    updateModified,
    circuitNumber,
    uptimeDurationInHuman,
    province,
    regency,
    arpIpAddresses,
    snHardware,
    gateways,
    interfaceStatus,
  } = device;

  const {data : lastRestartData, isLoading: lastRestartDataLoading} = useGetLastRestartHistoryQuery({serialNumber: sn})
  const [restartedSn, setRestartedSn] = useState(null)

  const [showPassword, setShowPassword] = useState(false);
  const { query } = useQuery();
  const browserLocation = useLocation();
  const [collapsible, setCollapsible] = useState({
    credentials: false,
    arpIpAddresses: false,
    lanPorts: false,
    gateways: false,
  });

  const onCollapsibleStateChange = (field) => {
    setCollapsible((state) => ({
      ...state,
      [field]: !Boolean(state[field]),
    }));
  };

  const filterDevice = (e, key, value) => {
    e.preventDefault();
    if (query.has(key)) {
      query.set(key, value);
    } else {
      query.append(key, value);
    }

    history.push({
      pathname: browserLocation.pathname,
      search: `?${query.toString()}`,
    });
  };

  const goToDeviceRegion = (e, regionId) => {
    e.preventDefault();
    history.push({
      pathname: `/admin/devices/region/${regionId}`,
      search: `?${query.toString()}`,
    });
  };

  const renderDeviceRegion = (stripped = false) => {
    return (
      <RowItem
        stripped={stripped}
        label="Region"
        value={
          region ? (
            <a
              href={`#region-${region?.id || Math.random()}`}
              className="text-sm text-default"
              onClick={(e) => goToDeviceRegion(e, region?.id)}
            >
              {region ? region.name : "-"}
            </a>
          ) : (
            "-"
          )
        }
      />
    );
  };

  const goToDeviceArea = (e, regionId, areaId) => {
    e.preventDefault();
    history.push({
      pathname: `/admin/devices/region/${regionId}/area/${areaId}`,
      search: `?${query.toString()}`,
    });
  };

  const renderDeviceArea = (stripped = false) => {
    return (
      <RowItem
        stripped={stripped}
        label="Area"
        value={
          area ? (
            <a
              href={`#area-${area?.id || Math.random()}`}
              className="text-sm text-default"
              onClick={(e) => goToDeviceArea(e, device.region?.id, area?.id)}
            >
              {area ? area.name : "-"}
            </a>
          ) : (
            "-"
          )
        }
      />
    );
  };
  const renderDeviceCustomer = (stripped = false) => {
    return (
      <RowItem
        stripped={stripped}
        label="Customer"
        value={
          customer ? (
            <a
              href={`#customer-${customer?.id || Math.random()}`}
              className="text-sm text-default"
              onClick={(e) => filterDevice(e, "customerIds", customer.id)}
            >
              {customer ? customer.name : "-"}
            </a>
          ) : (
            "-"
          )
        }
      />
    );
  };

  const renderLocation = (address, long, lat) => {
    if (!location) {
      return unknown;
    }
    return (
      <span>
        {address || unknown}
        {long && lat ? (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 text-default"
          >
            <i className="ni ni-square-pin"></i>
          </a>
        ) : (
          <i className="ni ni-square-pin ml-2"></i>
        )}
      </span>
    );
  };

  const renderLink = (key, value) => {
    if (!value) return unknown;
    return (
      <a
        key={value}
        href={`#link-${key}`}
        className="text-sm text-default"
        onClick={(e) => filterDevice(e, key, value)}
      >
        {value}
      </a>
    );
  };

  const renderInterfaceStat = (stat) => {
    const getColor = (status) => {
      switch (status) {
        case "Up":
          return "#2DCE89";
        case "Down":
          return "#F5365C";
        case "Standby":
          return "#8898AA";
        default:
          return "transparent";
      }
    }
    return (
      <span style={{ color: getColor(stat), fontWeight: '700'}}> ({stat})</span>
    )
  }

  const [isRestartLogModalOpen, setIsRestartLogModalOpen] = useState(false)

  const toogleRestartLogModal = () => {
    setIsRestartLogModalOpen(!isRestartLogModalOpen)
  }
  const handleClickRestartLog = (sn) => {
    setRestartedSn(sn)
    setIsRestartLogModalOpen(true)
  }

  return (
    <>
      <div>
        <h2 className="text-dark mt-3 ml--3">Device Overview</h2>
        <Row className="mt-4">
          <Col>
            <RowItem
              className="bg-default"
              label={<span className="text-white">Last Modified</span>}
              value={
                <span className="text-white">
                  {momentLastUpdate(updateModified)}
                </span>
              }
            />
            <RowItem
              label="Uptime Duration"
              value={uptimeDurationInHuman || unknown}
              stripped
            />
            <div>
              <RowItem
                label="Last Restart"
                value={
                  lastRestartDataLoading ? (
                    <Loading />
                  ) : lastRestartData ? (
                    <>
                      <div>{momentLastUpdate(lastRestartData)}</div>
                      <div className="text-link"></div>
                      <button
                        type="button"
                        onClick={() => handleClickRestartLog(sn)}
                        className="btn btn-link bg-transparent p-0"
                        style={{ fontWeight: "lighter" }}
                      >
                        See restart log
                      </button>
                    </>
                  ) : (
                    <span className="text-muted">No Data</span>
                  )
                } 
              />
            </div>
            <RowItem
              label="Device ID"
              value={circuitNumber || unknown}
            />
            <RowItem label="SN Hardware" value={snHardware || unknown} stripped/>
            <RowItem
              label="Serial Number"
              value={renderLink("sn", sn)}
            />
            <RowItem label="Device Type" value={type || unknown} stripped/>
            <RowItem label="Interface State" paddingBottom={4}/>
            <RowItem
              label="Main"
              value={
                <>
                  {interfaceStatus?.main?.network || unknown}
                  {renderInterfaceStat(interfaceStatus?.main?.status)}
                </>
              }
              offset
              padding={4}
              onlyLabelOffset
            />
            <RowItem
              label="Backup"
              value={
                <>
                  {interfaceStatus?.backup?.network || unknown}
                  {renderInterfaceStat(interfaceStatus?.backup?.status)}
                </>
              }
              offset
              padding={4}
              onlyLabelOffset
            />
            <RowItem
              label={<span style={{color: '#8898AA'}}>Blocked</span>}
              value={
                <span style={{color: '#8898AA'}}>
                  {interfaceStatus?.blocked?.network || unknown}
                </span>
              }
              paddingTop={4}
              offset
              onlyLabelOffset
            />
            <RowItem label="IP Address" value={ipDevice || unknown}  stripped/>
            <RowItem
              label="LAN Port Status"
              value={
                <span>
                  {getLanPortStatus()}
                  <button
                    id="togglerLanPortStatus"
                    type="button"
                    onClick={() => onCollapsibleStateChange("lanPorts")}
                    className="btn btn-sm btn-link bg-transparent ml-2"
                  >
                    <i
                      className={classnames("fa text-default", {
                        "fa-chevron-down": !Boolean(collapsible["lanPorts"]),
                        "fa-chevron-up": Boolean(collapsible["lanPorts"]),
                      })}
                    ></i>
                  </button>
                </span>
              }
            />
            <Collapse isOpen={Boolean(collapsible["lanPorts"])}>
              {lanPorts &&
                _.sortBy(lanPorts, "portNumber").map((lanPort, i) => (
                  <RowItem
                    stripped={i % 2 === 0}
                    offset
                    key={`lan-port-${lanPort.id}`}
                    label={`LAN Port ${lanPort.portNumber}`}
                    value={
                      <StatusBadge
                        label={
                          lanPort.status === "up" ? "Connected" : "Disconnected"
                        }
                        type={lanPort.status === "up" ? "success" : "danger"}
                      />
                    }
                  />
                ))}
            </Collapse>

            <RowItem
              stripped
              label="ARP IP Addresses"
              value={
                <span>
                  {`${arpIpAddresses.length || 0} Connected`}
                  <button
                    id="togglerARPIPAddress"
                    type="button"
                    onClick={() => onCollapsibleStateChange("arpIpAddresses")}
                    className="btn btn-sm btn-link bg-transparent ml-2"
                  >
                    <i
                      className={classnames("fa text-default", {
                        "fa-chevron-down": !Boolean(
                          collapsible["arpIpAddresses"]
                        ),
                        "fa-chevron-up": Boolean(collapsible["arpIpAddresses"]),
                      })}
                    ></i>
                  </button>
                </span>
              }
            />
            <Collapse isOpen={Boolean(collapsible["arpIpAddresses"])}>
              {arpIpAddresses &&
                arpIpAddresses.map((ipAddr, i) => (
                  <RowItem
                    stripped={i % 2 === 1}
                    offset
                    key={`lan-port-${ipAddr}`}
                    label={`IP Address`}
                    value={ipAddr}
                  />
                ))}
            </Collapse>

            <RowItem
              label="Gateway ID"
              value={
                <span>
                  {`${gateways.length || 0} ${
                    gateways.length > 1 ? "Gateways" : "Gateway"
                  }`}
                  <button
                    type="button"
                    onClick={() => onCollapsibleStateChange("gateways")}
                    className="btn btn-sm btn-link bg-transparent ml-2"
                  >
                    <i
                      className={classnames("fa text-default", {
                        "fa-chevron-down": !Boolean(collapsible["gateways"]),
                        "fa-chevron-up": Boolean(collapsible["gateways"]),
                      })}
                    ></i>
                  </button>
                </span>
              }
            />
            <Collapse isOpen={Boolean(collapsible["gateways"])}>
              {gateways &&
                _.sortBy(gateways, [(o) => o.id]).map((g, i) => (
                  <div key={i}>
                    <RowItem
                      offset
                      key={`gateway-id-${g.id}`}
                      stripped={i % 2 === 0}
                      label={`Gateway ID - ${i + 1}`}
                      value={
                        <span>
                          {g.gatewayId}
                          <button
                            id={`toggle-gw-${i}-${g.id}`}
                            type="button"
                            onClick={() =>
                              onCollapsibleStateChange(`gateway-${g.id}`)
                            }
                            className="btn btn-sm btn-link bg-transparent ml-2"
                          >
                            <i
                              className={classnames("fa text-default", {
                                "fa-chevron-down": !Boolean(
                                  collapsible[`gateway-${g.id}`]
                                ),
                                "fa-chevron-up": Boolean(
                                  collapsible[`gateway-${g.id}`]
                                ),
                              })}
                            ></i>
                          </button>
                        </span>
                      }
                    />
                    <Collapse isOpen={Boolean(collapsible[`gateway-${g.id}`])}>
                      <RowItem
                        stripped={i % 2 === 1}
                        leftCol="offset-2"
                        label="Peer IP"
                        value={g.peerIp || unknown}
                      />
                      <RowItem
                        stripped={i % 2 === 0}
                        leftCol="offset-2"
                        label="Peer ID"
                        value={g.peerId || unknown}
                      />
                      <RowItem
                        stripped={i % 2 === 1}
                        leftCol="offset-2"
                        label="Gateway Node"
                        value={g.node || unknown}
                      />
                    </Collapse>
                  </div>
                ))}
            </Collapse>

            <RowItem
              stripped
              label="Device Credential"
              value={
                <button
                  id="togglerCredential"
                  type="button"
                  onClick={() => onCollapsibleStateChange("credentials")}
                  className="btn btn-sm btn-link bg-transparent ml-2"
                >
                  <i
                    className={classnames("fa text-default", {
                      "fa-chevron-down": !Boolean(collapsible["credentials"]),
                      "fa-chevron-up": Boolean(collapsible["credentials"]),
                    })}
                  ></i>
                </button>
              }
            />
            <Collapse isOpen={Boolean(collapsible["credentials"])}>
              <RowItem label="Username" offset value={username} />
              <RowItem
                stripped
                label="Password"
                offset
                value={
                  <span>
                    {showPassword ? password : "*********"}
                    <span
                      className="btn btn-sm btn-link ml-2 pt-0 bg-transparent"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={classnames("text-default", {
                          "fa fa-eye": !showPassword,
                          "fa fa-eye-slash": showPassword,
                        })}
                      ></i>
                    </span>
                  </span>
                }
              />
            </Collapse>

            <RowItem
              label="Group"
              value={
                groups && groups.length > 0
                  ? groups.map((g, i) => {
                      const separator = ", ";
                      return (
                        <a
                          key={i}
                          href="#group"
                          className="text-sm text-default"
                          onClick={(e) => filterDevice(e, "groupIds", g.id)}
                        >
                          {g.name} {i < groups.length - 1 ? separator : ""}
                        </a>
                      );
                    })
                  : "-"
              }
            />
            {renderDeviceCustomer(true)}
            {renderDeviceRegion()}
            {renderDeviceArea(true)}
            <RowItem label="Province" value={province || unknown} />
            <RowItem label="District/City" value={regency || unknown} stripped />
            <RowItem
              label="Address"
              value={renderLocation(location, longitude, latitude)}
            />
          </Col>
        </Row>
      </div>
      <RestartLogModal toogleRestartLogModal={toogleRestartLogModal} isRestartLogModalOpen={isRestartLogModalOpen} sn={restartedSn}/>
    </>
  );
};

export default DeviceOverview;
