import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import AreaChart from "./AreaChart";

const TrafficInChart = ({ data, isLoading }) => {
  return (
    <Card className="border no-shadow">
      <CardBody>
        <CardTitle>
          <h5 className="h5 mb-0 text-muted">Traffic In</h5>
        </CardTitle>
        <AreaChart data={data} isLoading={isLoading} />
      </CardBody>
    </Card>
  );
};

export default TrafficInChart;
