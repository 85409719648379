import { SelectButton } from "primereact/selectbutton";
import React, { useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import InterfaceConfigurationForm from "../../InterfaceConfigurationForm";

const InterfaceTab = ({ interfaces = [] }) => {
  const [selectedInterface, setSelectedInterface] = useState(
    interfaces?.at(0)?.descr
  );

  const interfaceDetail = useMemo(() => {
    if (!selectedInterface) {
      return null;
    }

    return interfaces.find((d) => d.descr === selectedInterface);
  }, [interfaces, selectedInterface]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <SelectButton
            className="p-button-sm"
            value={selectedInterface}
            options={interfaces?.map(({ descr }) => descr)}
            onChange={(e) => setSelectedInterface(e.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {interfaceDetail ? (
            <InterfaceConfigurationForm data={interfaceDetail} viewMode />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default InterfaceTab;
