import EChartsReact from "echarts-for-react";
import moment from "moment";
import React from "react";

const RestartLogEChart = ( {data, isLoading}
) => {
  // Preprocess data to create a series of timestamps and corresponding statuses
  const barData = (data || []).map((item) => ({
    timestamp: moment(item.strTime).format("YYYY-MM-DD HH:mm:ss"), // Format timestamp
    status: item.status,
  }));

  // ECharts options
  const options = {
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        const { value, color } = params[0];
    
        // Cek apakah warna tidak sesuai
        if (color !== "#F5365C") {
          return null; // Tidak menampilkan tooltip
        }
    
        return `Restart time: ${moment(value[0]).format("DD-MM-YYYY HH:mm:ss")}`;
      },
    },    
    grid: {
      height: "40%",
      top: "5%",
      left: "2%",
      right: "2%",
      bottom: "5%",
    },
    xAxis: {
      type: "time",
      data: barData.map((item) => item.timestamp),
      axisLabel: {
        hideOverlap: true,
        align: "center",
        formatter: (value) => moment(value).format("D MMM"),
      },
      splitLine: {
        show: false,
      },
      name: "🟥 Down",
      nameGap: 30,
      nameLocation: "center",
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      show: false, 
    },
    series: [
      {
        name: "Status",
        type: "bar",
        barWidth: "100%",
        data: barData.map((item) => ({
          value: [item.timestamp, 1],
          itemStyle: {
            color: item.status === "up" ? "rgba(45, 206, 137, 0.1)" : "#F5365C",
          },
        })),
        emphasis: {
          focus: "series",
        },
      },
    ],
  };

  return isLoading ? (
    <EChartsReact
      option={options}
      style={{ height: "80px", width: "100%" }}
      showLoading={true}
    />
  ) : data && data.length > 0 ? (
    <EChartsReact
      option={options}
      style={{ height: "80px", width: "100%" }}
      showLoading={false}
    />
  ) : (
    <div
      style={{
        height: "80px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#888",
        fontSize: "14px",
      }}
    >
      No Data
    </div>
  );
};

export default RestartLogEChart;
