import { combineReducers } from "redux";
import { alertSubscriptionApi } from "../features/alert/service/alertSubscriptionApi";
import { alertConfigurationApi } from "./../features/alert/service/alertConfigurationApi";
import { alertHistoryApi } from "./../features/alert/service/alertHistoryApi";
import { areaApi } from "./../features/area/service/areaApi";
import { authReducer, authSlice } from "./../features/auth/reducers/authSlice";
import { authApi } from "./../features/auth/service/authApi";
import { customerApi } from "./../features/customers/service/customerApi";
import { dashboardApi } from "./../features/dashboard/service/dashboardApi";
import {
  deviceReducer,
  deviceSlice,
} from "./../features/device/reducers/deviceSlice";
import { deviceApi } from "./../features/device/service/deviceApi";
import { usageApi } from "./../features/device/service/usageApi";
import { groupApi } from "./../features/group/service/groupApi";
import { notificationApi } from "./../features/notification/service/notificationApi";
import { regionApi } from "./../features/region/service/regionApi";
import { reportApi } from "./../features/report/service/reportApi";
import { userActivityApi } from "./../features/user-activity/service/userActivityApi";
import { featureApi } from "./../features/user/service/featureApi";
import { roleApi } from "./../features/user/service/roleApi";
import { userApi } from "./../features/user/service/userApi";
import { referenceApi } from "./../services/referenceApi";
import { deviceInventoryApi } from "./../features/inventory/devices/services/deviceInventoryApi";
import { simcardInventoryApi } from "./../features/inventory/simcards/services/simcardInventoryApi";
import networkReducer, {
  networkSlice,
} from "./../features/network/networkSlice";
import networMemberkReducer, {
  networkMemberSlice,
} from "./../features/network/networkMemberSlice";
import { generateTokenApi } from "features/token/services/generateTokenApi";
import { oltApi } from "features/olt/service/oltApi";
import { policyApi } from "features/policy/service/policyApi";
import { packagePolicyApi } from "features/package-policy/service/packagePolicyApi";
import { onuInventoryApi } from "./../features/inventory/onu/service";
import { defaultApi } from "services/baseApi";
import { routerPolicyApi } from "features/router/routerPolicy/service/routerPolicyApi";
import { routerApi } from "features/router/routerList/service";
import { sdwanApi } from "features/sdwan/services/sdwanApi";
import { inventoryCustomerApi } from "features/inventory/customers/services";

export default combineReducers({
  [defaultApi.reducerPath]: defaultApi.reducer,
  [alertConfigurationApi.reducerPath]: alertConfigurationApi.reducer,
  [alertHistoryApi.reducerPath]: alertHistoryApi.reducer,
  [alertSubscriptionApi.reducerPath]: alertSubscriptionApi.reducer,
  [areaApi.reducerPath]: areaApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [authSlice.name]: authReducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [deviceApi.reducerPath]: deviceApi.reducer,
  [deviceSlice.name]: deviceReducer,
  [featureApi.reducerPath]: featureApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [referenceApi.reducerPath]: referenceApi.reducer,
  [regionApi.reducerPath]: regionApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [usageApi.reducerPath]: usageApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [userActivityApi.reducerPath]: userActivityApi.reducer,
  [deviceInventoryApi.reducerPath]: deviceInventoryApi.reducer,
  [simcardInventoryApi.reducerPath]: simcardInventoryApi.reducer,
  [networkSlice.name]: networkReducer,
  [networkMemberSlice.name]: networMemberkReducer,
  [generateTokenApi.reducerPath]: generateTokenApi.reducer,
  [oltApi.reducerPath]: oltApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [packagePolicyApi.reducerPath]: packagePolicyApi.reducer,
  [routerPolicyApi.reducerPath]: routerPolicyApi.reducer,
  [onuInventoryApi.reducerPath]: onuInventoryApi.reducer,
  [routerApi.reducerPath]: routerApi.reducer,
  [sdwanApi.reducerPath]: sdwanApi.reducer,
  [inventoryCustomerApi.reducerPath]: inventoryCustomerApi.reducer,
});
