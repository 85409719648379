import {
  ButtonLinkIcon,
  CustomExpandableIcon,
  PageContent,
  PrimeSearchInput,
} from "components";
import React, { useEffect, useMemo, useRef } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { useInventoryCustomerDatatable } from "../hooks/useInventoryCustomerDatatable";
import { StringParam, useQueryParams } from "use-query-params";
import { paginationRowsPerPageOptions } from "utils";
import CustomerRowDetail from "../components/CustomerRowDetail";
import { customStyles } from "utils/datatableStyles";
import { useModal } from "@ebay/nice-modal-react";
import CreateCustomerModal from "../components/CreateCustomerModal";

const CustomerListPage = () => {
  const customerModal = useModal(CreateCustomerModal);
  const [query, setQuery] = useQueryParams({
    smartSearch: StringParam,
  });
  const searchRef = useRef();

  const {
    query: { refetch },
    ...dt
  } = useInventoryCustomerDatatable(query);

  const columns = useMemo(() => {
    return [
      {
        name: "CUSTOMERS",
        selector: (row) => row?.name,
        sortable: true,
        sortField: "name",
        left: true,
      },
      {
        name: "DEVICE STATUSES",
        selector: (row) => row?.name,
        sortable: true,
        sortField: "name",
        left: true,
        cell: ({ deviceUp, deviceUnsynchronized, deviceDown }) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <span>
                <i
                  id="device-status-online"
                  className="fa fa-check-circle"
                  style={{ color: "#2DCE89" }}
                />
                <span className="ml-1">{deviceUp}</span>
                <UncontrolledTooltip placement="top" target="device-status-online">
                  Online
                </UncontrolledTooltip>
              </span>
              <span>
                <i
                  id="device-status-offline"
                  className="fa fa-exclamation-circle"
                  style={{ color: "#F5365C" }}
                />
                <span className="ml-1">{deviceDown}</span>
                <UncontrolledTooltip placement="top" target="device-status-offline">
                  Offline
                </UncontrolledTooltip>
              </span>
              <span>
                <i
                  id="device-status-unsync"
                  className="fa fa-question-circle"
                  style={{ color: "#8898AA" }}
                />
                <span className="ml-1">{deviceUnsynchronized}</span>
                <UncontrolledTooltip placement="top" target="device-status-unsync">
                  Unsynchronized
                </UncontrolledTooltip>
              </span>
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.smartSearch || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (searchText) => {
    setQuery({
      smartSearch:
        searchText !== "" && searchText !== null ? searchText : undefined,
    });
  };

  return (
    <PageContent title="Customers">
      <Card>
        <CardBody>
          <div
            className="d-flex align-items-center justify-content-end py-2 border-0"
            style={{ columnGap: 8 }}
          >
            <PrimeSearchInput
              ref={searchRef}
              onFilter={onSearch}
              size="sm"
              tooltip="Press 'Enter' to search."
              tooltipOptions={{ position: "top" }}
            />
            <ButtonLinkIcon
              label="Add New"
              onClick={() => customerModal.show().then(() => refetch())}
            />
          </div>
          <DataTable
            data={dt.data ?? []}
            columns={columns}
            defaultSortField="name"
            keyField="id"
            striped
            noHeader
            customStyles={customStyles}
            persistTableHead
            pagination
            paginationServer
            progressPending={dt.isLoading}
            paginationTotalRows={dt.totalRows}
            onChangeRowsPerPage={dt.onChangeRowsPerPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationPerPage={dt.pageSize}
            onChangePage={dt.onChangePage}
            sortServer
            onSort={dt.onSort}
            expandableRows
            expandableRowsComponent={CustomerRowDetail}
            expandableIcon={{
              collapsed: <CustomExpandableIcon type="collapsed" />,
              expanded: <CustomExpandableIcon type="expanded" />,
            }}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default CustomerListPage;
