import { StatusBadge } from "components";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";

const DeviceInfoCard = ({
  connectionStatus = "None",
  customerName = "None",
  interfaceDown = 0,
  interfaceNoCarrier = 0,
  interfaceUp = 0,
  provisionTemplate = "None",
  uptimeInSeconds = 0,
}) => {
  return (
    <Card style={{ height: 250 }}>
      <CardBody>
        <CardTitle className="d-flex justify-content-between border-0">
          <h5 className="h5 mb-0">Device Info</h5>
        </CardTitle>
        <div>
          <DescriptionItem
            label="Status"
            value={
              <StatusBadge
                label={connectionStatus}
                type={connectionStatus === "Connected" ? "success" : connectionStatus === "Disconnected" ? "danger" : "none"}
              />
            }
          />
          <DescriptionItem
            label="Uptime"
            value={moment.duration(uptimeInSeconds, "seconds").humanize()}
          />
          <DescriptionItem label="Customer" value={customerName} />
          <DescriptionItem
            label="Provisioning Template"
            value={provisionTemplate}
          />
          <DescriptionItem
            label="Interfaces"
            value={
              <>
                <Tooltip target=".interface-tooltip" position="top" />
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ columnGap: 8 }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ columnGap: 4 }}
                    >
                      <i
                        className="fas fa-arrow-up interface-tooltip"
                        data-pr-tooltip="Up"
                        style={{ color: "#2DCE89", fontSize: 12 }}
                      ></i>
                      <span className="text-muted">{interfaceUp}</span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ columnGap: 4 }}
                    >
                      <i
                        className="fas fa-arrow-down interface-tooltip"
                        data-pr-tooltip="Down"
                        style={{ color: "#F5365C", fontSize: 12 }}
                      ></i>
                      <span className="text-muted">{interfaceDown}</span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ columnGap: 4 }}
                    >
                      <i
                        className="fas fa-moon text-muted interface-tooltip"
                        data-pr-tooltip="No Carrier"
                        style={{ color: "#8898AA", fontSize: 12 }}
                      ></i>
                      <span className="text-muted">{interfaceNoCarrier}</span>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

const DescriptionItem = ({ label, value }) => {
  return (
    <div className="row" style={{ height: 30 }}>
      <div className="col-12 col-md-4 text-muted text-sm">{label}</div>
      <div className="col-12 col-md-8 text-sm">{value}</div>
    </div>
  );
};

export default DeviceInfoCard;
