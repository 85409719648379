import EChartsReact from "echarts-for-react";
import React from "react";
import { chartColors, formatBytes } from "utils";

const AreaChart = ({ data = [], isLoading, style = {} }) => {
  return (
    <EChartsReact
      style={{ height: "400px", ...style }}
      showLoading={isLoading}
      option={{
        legend: {
          show: true,
          bottom: 0,
          type: "scroll",
          icon: "rect",
        },
        grid: { top: 20, right: 0, left: 60 },
        tooltip: {
          trigger: "axis",
          valueFormatter: (value) => formatBytes(value),
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          axisLabel: {
            formatter: (value) => {
              return formatBytes(value);
            },
          },
        },
        xAxis: {
          type: "time",
          boundaryGap: false,
        },
        dataZoom: [{ type: "inside" }],
        color: chartColors,
        series:
          data && data.length > 0
            ? data.map(({ name, data }) => {
                return {
                  name: name,
                  type: "line",
                  smooth: true,
                  data: data,
                  symbol: "none",
                  areaStyle: {
                    opacity: 0.35,
                  }
                };
              })
            : [],
      }}
    />
  );
};

export default AreaChart;
