import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import appReducer from './reducers';
import { groupApi } from './../features/group/service/groupApi';
import { customerApi } from './../features/customers/service/customerApi';
import { alertConfigurationApi } from './../features/alert/service/alertConfigurationApi';
import { alertHistoryApi } from './../features/alert/service/alertHistoryApi';
import { alertSubscriptionApi } from '../features/alert/service/alertSubscriptionApi';
import { userApi } from './../features/user/service/userApi';
import { deviceApi } from './../features/device/service/deviceApi';
import { referenceApi } from './../services/referenceApi';
import { authApi } from './../features/auth/service/authApi';
import { dashboardApi } from './../features/dashboard/service/dashboardApi';
import { userActivityApi } from './../features/user-activity/service/userActivityApi';
import { notificationApi } from './../features/notification/service/notificationApi';
import { regionApi } from './../features/region/service/regionApi';
import { areaApi } from './../features/area/service/areaApi';
import { usageApi } from './../features/device/service/usageApi';
import { reportApi } from './../features/report/service/reportApi';
import { deviceInventoryApi } from './../features/inventory/devices/services/deviceInventoryApi';
import { simcardInventoryApi } from './../features/inventory/simcards/services/simcardInventoryApi';

// import { rtkQueryErrorLogger } from './middlewares';
import { roleApi } from './../features/user/service/roleApi';
import { featureApi } from './../features/user/service/featureApi';
import { RESET_STATE_ACTION_TYPE } from './actions';
import { generateTokenApi } from 'features/token/services/generateTokenApi';
import { oltApi } from 'features/olt/service/oltApi';
import { policyApi } from 'features/policy/service/policyApi';
import {packagePolicyApi} from 'features/package-policy/service/packagePolicyApi'
import { onuInventoryApi } from './../features/inventory/onu/service';
import { defaultApi } from 'services/baseApi';
import { routerPolicyApi } from 'features/router/routerPolicy/service/routerPolicyApi';
import { routerApi } from 'features/router/routerList/service';
import { sdwanApi } from 'features/sdwan/services/sdwanApi';
import { inventoryCustomerApi } from 'features/inventory/customers/services';

const persistConfig = {
  key:
    process.env.NODE_ENV === `development`
      ? 'iot-device-management-web'
      : 'iot-device-management-web-prod',
  version: 1,
  storage,
  whitelist: ['auth', 'devices'],
};

const middleware = [];

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {};
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat([
        groupApi.middleware,
        customerApi.middleware,
        alertConfigurationApi.middleware,
        alertHistoryApi.middleware,
        alertSubscriptionApi.middleware,
        userApi.middleware,
        deviceApi.middleware,
        referenceApi.middleware,
        authApi.middleware,
        dashboardApi.middleware,
        userActivityApi.middleware,
        notificationApi.middleware,
        regionApi.middleware,
        areaApi.middleware,
        roleApi.middleware,
        featureApi.middleware,
        usageApi.middleware,
        reportApi.middleware,
        deviceInventoryApi.middleware,
        simcardInventoryApi.middleware,
        generateTokenApi.middleware,
        oltApi.middleware,
        policyApi.middleware,
        packagePolicyApi.middleware,
        routerPolicyApi.middleware,
        onuInventoryApi.middleware,
        routerApi.middleware,
        defaultApi.middleware,
        sdwanApi.middleware,
        inventoryCustomerApi.middleware,
      ])
      .concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;

setupListeners(store.dispatch);
