import React, { useEffect, useMemo } from "react";

import { FullscreenLoading, PageContent } from "components";
import InterfaceConfigurationForm from "../component/Form/InterfaceConfigurationForm";
import PageToolbar from "../component/PageToolbar";
import { useParams } from "react-router";
import { useDeviceInterface } from "../hooks/useDeviceInterface";
import { useUpdateInterface } from "../hooks/useUpdateInterface";
import { history } from "utils";
import { toast } from "react-toastify";

const InterfaceConfiguration = () => {
  const { sn, interfaceId } = useParams();
  const { data, isLoading, isError } = useDeviceInterface({interfaceId, sn});
  const updateConfigInterface = useUpdateInterface({id : interfaceId, sn});

  useEffect(() => {
    if (isError) {
      history.goBack()
      toast.error('Something went wrong, please try again') 
    }
  }, [isError])


  const title = useMemo(() => {
    if (!data) return "Interface Configuration";
    const { descr } = data.data;
    return `${sn}'s ${descr} Configuration`;
  }, [data, sn]);

  const onSubmitInterface = (interfaceData) => {
    updateConfigInterface(interfaceData)
  }

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} pageTitle={interfaceId} />
      {isLoading ? (
        <FullscreenLoading />
      ) : (
        <InterfaceConfigurationForm data={data?.data} onSubmitInterface={onSubmitInterface} />
      )}
    </PageContent>
  );
};

export default InterfaceConfiguration;
