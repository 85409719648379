import React from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardTitle } from "reactstrap";
import { formatBytes, formatNumber } from "utils";
import { customStyles } from "utils/datatableStyles";

const TrafficOverviewTable = ({ data = [], isLoading }) => {
  return (
    <Card className="border no-shadow">
      <CardBody>
        <CardTitle>
          <h5 className="h5 mb-0 text-muted">Total</h5>
        </CardTitle>
        <DataTable
          data={data}
          progressPending={isLoading}
          columns={[
            {
              name: "Interface",
              width: '200px',
              selector: (row) => row.interfaceName,
              sortable: true,
              cell: ({ interfaceName, interfaceStatus }) => {
                return (
                  <div className="">
                    <div className="mb-1 text-uppercase">{interfaceName}</div>
                    {interfaceStatus === "down" && (
                      <span className="flex align-items-center text-sm">
                        <i className="fa fa-arrow-down text-danger mr-1" /> <span className="text-muted text-sm">Down</span>
                      </span>
                    )}
                    {interfaceStatus === "up" && (
                      <span className="flex align-items-center text-sm">
                        <i className="fa fa-arrow-up text-success mr-1" /> <span className="text-muted text-sm">Up</span>
                      </span>
                    )}
                    {interfaceStatus === "no carrier" && (
                      <span className="flex align-items-center text-sm">
                        <i className="fa fa-question-circle text-gray mr-1" /> <span className="text-muted text-sm">No Carrier</span>
                      </span>
                    )}
                  </div>
                );
              },
            },
            {
              name: "BYTES IN/OUT",
              selector: (row) => row.bytesReceived,
              sortable: true,
              cell: ({ bytesReceived, bytesTransmitted }) => {
                return (
                  <span>
                    {formatBytes(bytesReceived, 2)} /{" "}
                    {formatBytes(bytesTransmitted, 2)}
                  </span>
                );
              },
            },
            {
              name: "PACKETS IN/OUT",
              selector: (row) => row.packetsReceived,
              sortable: true,
              cell: ({ packetsReceived, packetsTransmitted }) => {
                return (
                  <span>
                    {formatNumber(packetsReceived)} /{" "}
                    {formatNumber(packetsTransmitted)}
                  </span>
                );
              },
            },
          ]}
          striped
          noHeader
          persistTableHead
          customStyles={{
            ...customStyles,
            tableWrapper: {
              style: {
                height: undefined,
              },
            },
          }}
        />
      </CardBody>
    </Card>
  );
};

export default TrafficOverviewTable;
