import { PageContent, PrimeSearchInput } from "components";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Button, Card, CardBody } from "reactstrap";
import { customStyles3 } from "utils/datatableStyles";
import { ConfigureInterfaceColumn } from "../component";
import { history, paginationRowsPerPageOptions } from "utils";
import { useParams } from "react-router";
import PageToolbar from "../component/PageToolbar";
import { nullableStringColumn } from "utils/datatablesColumn";
import { useDeviceInterfacesDatatable } from "../hooks/useDeviceInterfacesDatatable";
import { RefreshButton } from "components/atoms/RefreshButton";
import { useTableColumnConfig } from "../hooks/useTableColumnConfig";

const defaultVisibleColumn = [
  "status",
  "name",
  "networkDeviceName",
  "vlan",
  "linkType",
  "ipv4Address",
  "ipv6Address",
  "gateway",
  "routes",
];
const columnOptions = [
  { className: "text-xs", value: "status", label: "Status" },
  { className: "text-xs", value: "name", label: "Interface" },
  { className: "text-xs", value: "networkDeviceName", label: "Device" },
  { className: "text-xs", value: "vlan", label: "VLAN" },
  { className: "text-xs", value: "linkType", label: "Link Type" },
  { className: "text-xs", value: "ipv4Address", label: "IPv4" },
  { className: "text-xs", value: "ipv6Address", label: "IPv6" },
  { className: "text-xs", value: "gateway", label: "Gateway" },
  { className: "text-xs", value: "routes", label: "Routes" },
];

const InterfaceList = () => {
  const { sn } = useParams();
  const {
    data,
    isLoading,
    isFetching,
    totalRows,
    pageSize,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
    refetch,
    setSmartSearch,
  } = useDeviceInterfacesDatatable(sn);

  const [visibleColumns, setVisibleColumns] = useTableColumnConfig(
    "sdwan-interface-table-config",
    defaultVisibleColumn
  );

  const columns = useMemo(() => {
    const renderStatus = (status) => {
      switch (status) {
        case "up":
          return (
            <i
              className="mdi mdi-wifi"
              style={{
                fontSize: 16,
                color: "#2DCE89",
                transform: "scale(1.2)",
              }}
              title={status}
            />
          );
        case "down":
          return (
            <i
              className="mdi mdi-wifi-off"
              style={{
                fontSize: 16,
                color: "#F5365C",
                transform: "scale(1.2)",
              }}
              title={status}
            />
          );
        case "no carrier":
          return (
            <i
              className="mdi mdi-wifi-strength-outline"
              style={{
                fontSize: 16,
                color: "#8898AA",
                transform: "scale(1.2)",
              }}
              title={status}
            />
          );
        default:
          return <span>{status}</span>;
      }
    };

    const renderAction = (row) => {
      // if (["lo0"].includes(row.networkDeviceName)) return null;

      const action = () => {
        if (
          row.networkDeviceName &&
          row.networkDeviceName.toLowerCase().includes("ppp")
          // row.description &&
          // row.description.toLowerCase().includes("4gnet")
        ) {
          return history.push(
            `/admin/sdwan/device/${sn}/interfaces/${row.name}/4g-configuration`
          );
        }
        return history.push(
          `/admin/sdwan/device/${sn}/interfaces/${row.name}/configuration`
        );
      };

      return (
        <Button
          size="sm"
          color="link"
          className="p-0 m-0 d-flex align-items-center"
          style={{ transform: "scale(0.65)", transformOrigin: "center" }}
          onClick={action}
          disabled={
            (row.networkDeviceName &&
            row.networkDeviceName.toLowerCase().includes("ppp")) ||
            ["lo0"].includes(row.networkDeviceName) ||
            !row.name
          }
        >
          <i className="fas fa-cog"></i>
        </Button>
      );
    };

    const renderIpAddresses = (ipAddresses) => {
      if (!ipAddresses || ipAddresses.length === 0) return "-";

      return (
        <div className="d-flex flex-column">
          {ipAddresses.map((ip) => (
            <div key={`ip-${ip.id}`}>
              <span>
                {ip.ipaddr}/{ip.subnet}
              </span>
            </div>
          ))}
        </div>
      );
    };

    const renderGateway = ({ gateway }) => {
      if (!gateway) return "-";

      return (
        <div className="d-flex flex-column">
          {gateway}
        </div>
      );
    };

    const renderRoutes = ({ routes }) => {
      if (!routes || routes.length === 0) return "-";

      return (
        <div className="d-flex flex-column">
          {routes.map((ip, id) => {
            const color = ip.ipAddress === "default" ? "#2DCE89" : undefined;
            return (
              <div key={`ip-${id}`} style={{ color, lineHeight: 1.5 }}>
                {ip.ipAddress}
              </div>
            );
          })}
        </div>
      );
    };

    return [
      {
        name: "Status",
        selector: (row) => row?.status,
        center: true,
        cell: ({ status }) => renderStatus(status),
        omit: visibleColumns?.includes("status") ? false : true,
        sortable: true,
        sortField: "status",
        id: "status",
        width: "100px",
      },
      {
        name: "Interface",
        selector: (row) => row?.name,
        left: true,
        omit: visibleColumns?.includes("name") ? false : true,
        sortable: true,
        id: "name",
        sortField: "name",
        cell: (row) => (
          <span>
            {row.description} {row.name ? `(${row.name})` : ''}
          </span>
        ),
      },
      {
        name: "Device",
        selector: (row) => row?.networkDeviceName,
        left: true,
        omit: visibleColumns?.includes("networkDeviceName") ? false : true,
        sortable: true,
        id: "networkDeviceName",
        sortField: "networkDeviceName",
      },
      {
        name: "VLAN",
        selector: (row) => row?.vlanTag,
        cell: (row) => row.vlanTag ? row.vlanTag : "-",
        left: true,
        omit: visibleColumns?.includes("vlan") ? false : true,
        sortable: false,
        id: "vlan",
      },
      {
        name: "Link Type",
        selector: (row) => row?.linkType,
        cell: (row) => nullableStringColumn(row.linkType),
        left: true,
        omit: visibleColumns?.includes("linkType") ? false : true,
        sortable: true,
        id: "linkType",
        sortField: "linkType",
      },
      {
        name: "IPv4",
        selector: (row) => row?.ipv4Address,
        cell: (row) => renderIpAddresses(row.ipv4Address),
        left: true,
        wrap: true,
        omit: visibleColumns?.includes("ipv4Address") ? false : true,
        sortable: true,
        id: "ipv4Address",
        sortField: "ipv4Address",
      },
      {
        name: "IPv6",
        selector: (row) => row?.ipv6Address,
        cell: (row) => renderIpAddresses(row.ipv4Address),
        left: true,
        wrap: true,
        omit: visibleColumns?.includes("ipv6Address") ? false : true,
        sortable: true,
        id: "ipv6Address",
        sortField: "ipv6Address",
      },
      {
        name: "Gateway",
        selector: (row) => row?.gateway,
        cell: renderGateway,
        left: true,
        omit: visibleColumns?.includes("gateway") ? false : true,
        sortable: true,
        id: "gateway",
        sortField: "gateway",
      },
      {
        name: "Routes",
        selector: (row) => row?.routes,
        cell: renderRoutes,
        left: true,
        omit: visibleColumns?.includes("routes") ? false : true,
        sortable: true,
        id: "routes",
        sortField: "routes",
        width: "300px",
      },
      {
        width: "40px",
        cell: renderAction,
        allowOverflow: true,
        button: true,
      },
    ];
  }, [sn, visibleColumns]);

  const onSubmitConfigurationColumns = (columns) => {
    setVisibleColumns(columns);
  };

  const title = useMemo(() => {
    if (!sn) return "Interfaces";
    return `${sn}'s Interfaces`;
  }, [sn]);

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} pageTitle={sn} />
      <Card>
        <CardBody>
          <div
            className="mb-3 d-flex align-items-center"
            style={{ columnGap: 4 }}
          >
            <PrimeSearchInput
              onFilter={(searchText) => setSmartSearch(searchText)}
              block
              size="sm"
              tooltip="Press 'Enter' to search."
              tooltipOptions={{ position: "top" }}
              fullwidth
            />
            <ConfigureInterfaceColumn
              onSubmit={onSubmitConfigurationColumns}
              columnOptions={columnOptions}
              visibleColumns={visibleColumns}
            />
            <RefreshButton
              isLoading={isFetching || isLoading}
              onClick={() => refetch()}
            />
          </div>
          <DataTable
            data={data}
            columns={columns}
            responsive={true}
            keyField="id"
            defaultSortFieldId="status"
            defaultSortAsc={true}
            striped
            noHeader
            highlightOnHover
            persistTableHead
            progressPending={isLoading || isFetching}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={onChangePage}
            sortServer
            onSort={onSort}
            customStyles={customStyles3}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default InterfaceList;
