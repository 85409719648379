import React from "react";
import { BootstrapDateRangePicker } from "components";
import moment from "moment";
import { Card, CardBody, CardTitle } from "reactstrap";
import TrafficInChart from "./charts/TrafficInChart";
import TrafficOutChart from "./charts/TrafficOutChart";
import TrafficOverviewTable from "./TrafficOverviewTable";

const dateRangeOptions = {
  ranges: {
    Today: [moment(), moment()],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 14 Days": [moment().subtract(13, "day"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
  },
  locale: {
    format: "MM/DD/YYYY",
    separator: " - ",
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    fromLabel: "From",
    toLabel: "To",
    customRangeLabel: "Custom",
    weekLabel: "W",
    daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    firstDay: 1,
  },
  alwaysShowCalendars: false,
  startDate: moment(),
  endDate: moment(),
  opens: "left",
  drops: "bottom",
  cancelClass: "btn-secondary",
};

const DeviceInformationCard = ({
  table = [],
  trafficInData = [],
  trafficOutData = [],
  onDateChange,
  isLoading,
}) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="d-flex justify-content-between align-items-center">
          <h5 className="h5 mb-0">Interface Traffic</h5>
          <div>
            <BootstrapDateRangePicker
              alwaysShowCalendars={false}
              options={dateRangeOptions}
              onDateChange={onDateChange}
            />
          </div>
        </CardTitle>
        <div className="row">
          <div className="col-12 col-md-6">
            <TrafficInChart data={trafficInData} isLoading={isLoading} />
          </div>
          <div className="col-12 col-md-6">
            <TrafficOutChart data={trafficOutData} isLoading={isLoading} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TrafficOverviewTable data={table} isLoading={isLoading} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DeviceInformationCard;
