import { PageContent } from "components";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import PageToolbar from "../component/PageToolbar";
import { Col, Row } from "reactstrap";
import GatewayStatusCard from "../component/overview/GatewayStatusCard";
import DeviceInfoCard from "../component/overview/DeivceInfoCard";
import DeviceInformationCard from "../component/overview/DeviceInformationCard";
import {
  useGetDeviceOverviewQuery,
  useGetInterfaceTrafficQuery,
} from "../services";
import moment from "moment";
import { NumberParam, useQueryParams } from "use-query-params";
import { groupBy } from "lodash";
import { isObjectEmpty } from "utils";


const DeviceOverview = () => {
  const { sn } = useParams();
  const [filters, setFilters] = useQueryParams({
    startTs: NumberParam,
    endTs: NumberParam,
  });
  const { data, isLoading } = useGetDeviceOverviewQuery({ sn }, { skip: !sn });
  const {
    data: trafficData,
    isLoading: isLoadingTraffic,
    isFetching: isFetchingTraffic,
  } = useGetInterfaceTrafficQuery(
    {
      sn,
      startTs: filters.startTs ?? moment().startOf("day").valueOf(),
      endTs: filters.endTs ?? moment().endOf("day").valueOf(),
    },
    { skip: !sn }
  );

  const onDateChange = (start, end, label) => {
    setFilters({
      startTs: start.startOf("day").valueOf(),
      endTs: end.endOf("day").valueOf(),
    });
  };

  const overviewData = useMemo(() => {
    if (!trafficData) {
      return {
        table: [],
        trafficInData: [],
        trafficOutData: [],
      };
    }

    const chartData = trafficData.chart && trafficData.chart.length > 0 ? trafficData.chart : [];
    const trafficInData = [];
    const trafficOutData = [];

    const groupByInterface = groupBy(chartData, "interfaceName");

    if (!isObjectEmpty(groupByInterface)) {
      Object.entries(groupByInterface).forEach(([interfaceName, datas]) => {
        trafficInData.push({
          name: interfaceName,
          data: datas.map(({ time, diffBytesReceived }) => [time, diffBytesReceived]),
        });

        trafficOutData.push({
          name: interfaceName,
          data: datas.map(({ time, diffBytesTransmitted }) => [
            time,
            diffBytesTransmitted,
          ]),
        });
      });
    }

    return {
      table: trafficData.table,
      trafficInData,
      trafficOutData,
    };
  }, [trafficData]);

  const title = useMemo(() => {
    if (!sn) return "Overview";
    return `${sn}'s Overview`;
  }, [sn]);

  return (
    <PageContent
      headerStyle={{ paddingBottom: "16rem" }}
      style={{ marginTop: "-16rem" }}
    >
      <PageToolbar title={title} pageTitle={sn} />

      <Row>
        <Col sm="12" md="6">
          <GatewayStatusCard
            gateways={data?.gatewayInfo || []}
            isLoading={isLoading}
          />
        </Col>
        <Col sm="12" md="6">
          <DeviceInfoCard
            connectionStatus={data?.deviceInfo?.connectionStatus}
            customerName={data?.deviceInfo?.customerName}
            interfaceDown={data?.deviceInfo?.interfaceDown}
            interfaceNoCarrier={data?.deviceInfo?.interfaceNoCarrier}
            interfaceUp={data?.deviceInfo?.interfaceUp}
            provisionTemplate={data?.deviceInfo?.provisionTemplate}
            uptimeInSeconds={data?.deviceInfo?.uptimeInSeconds}
          />
        </Col>
        <Col className="col-12">
          <DeviceInformationCard
            trafficInData={overviewData.trafficInData}
            trafficOutData={overviewData.trafficOutData}
            table={overviewData.table}
            isLoading={isLoadingTraffic || isFetchingTraffic}
            onDateChange={onDateChange}
          />
        </Col>
      </Row>
    </PageContent>
  );
};

export default DeviceOverview;
