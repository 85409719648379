import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loading } from 'components/atoms';

const Card = styled.div`
  display: block;
  box-shadow: 0 0 .5rem 0 rgb(136 152 170 / 50%);
  border-radius: 0.375rem;
  margin-bottom: 1rem;
`;

const CardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem 1rem;
`;

const Title = styled.div`
  color: ${props => props.color};
  margin-bottom: .5rem;
  font-weight: 600;
`;

Title.defaultProps = {
  color: "#2065AC"
}

const Value = styled.span`
  color: ${props => props.color};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
`

Value.defaultProps = {
  color: "#2065AC"
}

const UnitValue = styled.span`
  color: "#2065AC";
  font-weight: normal;
  margin-left: 0.5rem;
`;

const ValueWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const StatusText = styled.span`
  color: "#000";
  margin-left: .5rem;
`;

const StatusIcon = ({ status, reverse }) => {
  if (! ['up', 'down'].includes(status)) {
    return <i className="fa fa-arrows-alt-h text-grey"></i>;
  }
  const icon = `fa fa-arrow-${status}`;
  const color = reverse === true ? (status === 'up' ? 'text-danger' : 'text-success') : (status === 'up' ? 'text-success' : 'text-danger');

  return <i className={`${icon} ${color}`}></i>;
}

const SLACard = props => {
  return (
    <Card style={{boxShadow: 'none', borderRadius: '8px', border: `1px solid #E9ECEF`}}>
      <CardBody>
        <Title color={props.titleColor}>{props.title}</Title>
        {props.isLoading ? 
          <div className="d-flex justify-content-center align-items-center h-100 mb-3">
            <Loading size="lg" />
          </div>
          :
          <>
            <ValueWrapper>
              <Value color={props.valueColor}>
                {props.value}
              </Value>
              {props.unitValue && <UnitValue>{props.unitValue}</UnitValue>}
            </ValueWrapper>
            <div>
              {props.status && <StatusIcon status={props.status} reverse={props.reverseStatus} />}
              {props.statusText && <StatusText>{props.statusText}</StatusText>}
            </div>
          </>
        }
      </CardBody>
    </Card>
  )
}

SLACard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unitValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  status: PropTypes.string,
  reverseStatus: PropTypes.bool,
  isLoading: PropTypes.bool,
  statusText: PropTypes.string,
  titleColor: PropTypes.string,
  valueColor: PropTypes.string,
}

SLACard.defaultProps = {
  title: 'Title',
  value: '0',
  reverseStatus: false,
  isLoading: false
}

export default SLACard
