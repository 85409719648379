import React from "react";

import { PageContent } from "components";
import InterfaceConfiguration4GForm from "../component/Form/InterfaceConfiguration4GForm";
import PageToolbar from "../component/PageToolbar";
import { useParams } from "react-router";

const InterfaceConfiguration4G = () => {
  const { interfaceId } = useParams();
  return (
    <PageContent style={{ marginTop: '-14rem'}}>
      <PageToolbar title='4GNet Configuration' pageTitle={interfaceId} />
      <InterfaceConfiguration4GForm />
    </PageContent>
  );
};

export default InterfaceConfiguration4G;
