// DASHBOARD
export const VIEW_DASHBOARD = 'View Dashboard';
export const CONFIG_DASHBOARD = 'Config Dashboard';

// DEVICE
export const TABLE_CONFIGURATION = 'Device Table Configuration';
export const FILTER_DEVICE = 'Filter Device';
export const VIEW_LIST_OF_DEVICE = 'View List of Device';
export const ADD_NEW_DEVICE = 'Add New Device';
export const DELETE_DEVICE = 'Delete Device';
export const CONFIG_DEVICE_GROUP = 'Config Device Group ';
export const UPDATE_DEVICE_REGION = 'Update Device Region';
export const UPDATE_DEVICE_AREA = 'Update Device Area';
export const UPDATE_DEVICE_CUSTOMER = 'Update Device Customer';
export const VIEW_DEVICE_OVERVIEW = 'View Device Overview';
export const VIEW_DEVICE_USAGE_TREND = 'View Device Usage Trend';
export const VIEW_DEVICE_SIGNAL_TREND = 'View Device Signal Trend';
export const VIEW_DEVICE_BANDWIDTH_TREND = 'View Device Bandwidth Trend';
export const CONFIGURE_DEVICE = 'Configure Device';
export const VIEW_DEVICE_CREDENTIALS = 'View Device Credentials';
export const MANAGE_DEVICE_CREDENTIALS = 'Manage Device Credentials';
export const VIEW_UNACTIVATED_DEVICE = 'View Unactivated Devices';
export const ACTIVATE_UNACTIVATED_DEVICE = 'Activate Device';
export const BLOCK_UNACTIVATED_DEVICE = 'Block Device';

export const REBOOT_DEVICE = 'Reboot Device';
export const ENABLE_DISABLE_TUNNEL = 'Enable/Disable Tunnel';
export const UPDATE_DEVICE_FIRMWARE = 'Update Firmware';

export const CONFIGURE_BANDWIDTH_SCHEDULE = 'Configure Bandwidth Schedule';

// DEVICE SIM CARD
export const CONFIG_PRIMARY_SIM = 'Config Primary SIM';
export const CHANGE_SIM_QUOTA = 'Change SIM Quota';
export const CHANGE_SIM_STATUS = 'Change SIM Status';

// GROUP
export const ADD_NEW_GROUP = 'Add New Group';
export const UPDATE_GROUP = 'Update Group';
export const DELETE_GROUP = 'Delete Group';
export const VIEW_GROUP = 'View Group List';

// REGION
export const ADD_NEW_REGION = 'Add New Region';
export const ADD_REGION = 'Add Region';
export const UPDATE_REGION = 'Update Region';
export const DELETE_REGION = 'Delete Region';
export const VIEW_REGION_LIST = 'View Region List';

// AREA
export const ADD_NEW_AREA = 'Add New Area';
export const UPDATE_AREA = 'Update Area';
export const DELETE_AREA = 'Delete Area';
export const VIEW_LIST_AREA = 'View List Area';

// USER
export const VIEW_USER_LIST = 'View User List';
export const VIEW_USER_DETAILS = 'View User Details';
export const DELETE_USER = 'Delete User';
export const ADD_NEW_USER = 'Add New User';
export const UPDATE_USER_PROFILE = 'Update User Profile';
export const UPDATE_USER_ROLE = 'Update User Role';
export const UPDATE_USER_PRIVILEGE = 'Update User Privilege';
export const UPDATE_USER_GROUP = 'Update User Group';
export const UPDATE_USER_ELIGIBL_ZONE = 'Update User Eligible Zone';
export const UPDATE_USER_ELIGIBLE_CUSTOMER = 'Update User Eligible Customer';
export const VIEW_USER_CREDENTIALS = 'View User Credentials';
export const MANAGE_USER_CREDENTIALS = 'Manage User Credentials';

// USER ACTIVITY LOG
export const VIEW_USER_ACTIVITY_LOG = 'View User Activity Log';
export const DELETE_USER_ACTIVITY_LOG = 'Delete User Activity Log';

// ALERT
export const VIEW_ALL_ALERT = 'View All Alert';
export const VIEW_ALERT_DETAILS = 'View Alert Details';
export const VIEW_ALERT_RULE = 'View Alert Rule';
export const VIEW_ALERT_RULE_DETAILS = 'View Alert Rule Details';
export const CONFIGURE_ALERT_RULE = 'Configure Alert Rule';
export const DELETE_ALERT_RULE = 'Delete Alert Rule';
export const ADD_NEW_ALERT_RULE = 'Add New Alert Rule';

// REPORT
export const VIEW_USAGE_REPORT = 'View Usage Report';
export const DOWNLOAD_USAGE_REPORT = 'Download Usage Report';

// CUSTOMER
export const VIEW_CUSTOMER = 'View Customer List';
export const CREATE_CUSTOMER = 'Add New Customer';
export const UPDATE_CUSTOMER = 'Update Customer';
export const DELETE_CUSTOMER = 'Delete Customer';

// DEVICE INVENTORY
export const VIEW_DEVICE_INVENTORY_LIST = 'View Device Inventory List';
export const CREATE_DEVICE_INVENTORY = 'Create Device Inventory';
export const UPDATE_DEVICE_INVENTORY = 'Update Device Inventory';
export const DELETE_DEVICE_INVENTORY = 'Delete Device Inventory';
export const PROVISION_DEVICE = 'Provision Device';
export const UNPROVISION_DEVICE = 'Unprovision Device';

// SIMCARD INVENTORY
export const VIEW_SIMCARD_INVENTORY_LIST = 'View SIM Inventory List';
export const CREATE_SIMCARD_INVENTORY = 'Create SIM Inventory';
export const UPDATE_SIMCARD_INVENTORY = 'Update SIM Inventory';
export const DELETE_SIMCARD_INVENTORY = 'Delete SIM Inventory';
export const ASSIGN_SIMCARD = 'Assign SIM';
export const UNASSIGN_SIMCARD = 'Unassign SIM';

// NETWORK
export const VIEW_NETWORKS = 'View Networks';

// GENERATE TOKEN
export const GENERATE_API_TOKEN = 'Generate Api Token';

// LICENSE MANAGEMENT

export const VIEW_LICENSE = 'View License';
export const CREATE_LICENSE = 'Create License';
export const EDIT_LICENSE = 'Edit License';
export const DELETE_LICENSE = 'Delete License';

// Router Management

export const VIEW_ROUTER = 'View Router';
export const CREATE_ROUTER = 'Create Router';
export const EDIT_ROUTER = 'Edit Router';
export const DELETE_ROUTER = 'Delete Router';

// OLT ONT
export const VIEW_OLT_ONT = 'View OLT - ONT';
export const CREATE_OLT_ONT = 'Create OLT - ONT';
export const EDIT_OLT_ONT = 'Edit OLT - ONT';
export const DELETE_OLT_ONT = 'Delete OLT - ONT';

export const VIEW_BANDWIDTH_POLICY = 'View Bandwidth Policy';
export const CREATE_BANDWIDTH_POLICY = 'Create Bandwidth Policy';
export const UPDATE_BANDWIDTH_POLICY = 'Update Bandwidth Policy';
export const DELETE_BANDWIDTH_POLICY = 'Delete Bandwidth Policy';

export const VIEW_PACKAGE_POLICY = 'View Package Policy';
export const CREATE_PACKAGE_POLICY = 'Create Package Policy';
export const UPDATE_PACKAGE_POLICY = 'Update Package Policy';
export const DELETE_PACKAGE_POLICY = 'Delete Package Policy';

// ROLE MANAGEMENT
export const VIEW_ROLES = 'View All Roles';
export const CREATE_ROLE = 'Create Role';
export const UPDATE_ROLE = 'Update Role';
export const DELETE_ROLE = 'Delete Role';

export const VIEW_ONU_ONT_LIST = 'View ONU/OLT List';

export const VIEW_SDWAN_FEATURES = 'Has SDWAN Features';