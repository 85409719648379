import { BootstrapDateRangePicker, PrimeDropdown } from "components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Col, Modal, Row } from "reactstrap";
import { customStyles3 } from "utils/datatableStyles";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";
import { paginationRowsPerPageOptions } from "utils";
import { useGetDeviceAvailabilityReportDailyByUsernameV2Query } from "../service/reportApi";
import { useGetavailabilityDetailMutation } from "features/device/service/usageApi";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import ReportHeatmapEchart from "./ReportHeatmapEchart";

const DeviceAvailabilityDetailModal = ({
  isSnModalOpen,
  toggleSnModal,
  snList = [],
  sn = undefined,
  dateFilter,
}) => {
  const [selectedSn, setSelectedSn] = useState(sn);
  const [downStatusOnly, setDownStatusOnly] = useState(false);
  const [timeGroup, setTimeGroup] = useState("3m");

  const defaultParamsState = {
    startTs: dateFilter.startTime.toDate().getTime(),
    endTs: dateFilter.endTime.toDate().getTime(),
    availability: undefined,
    snsAsString: selectedSn,
  };

  const defaultDatePicker = {
    startTime: dateFilter.startTime,
    endTime: dateFilter.endTime,
    label: dateFilter.label,
  };

  const defaultHourlyParamsState = {
    endTs: undefined,
    startTs: undefined,
    timeGroupping: timeGroup,
    status: downStatusOnly ? "down" : undefined,
  };

  const [params, setParams] = useState(defaultParamsState);
  const [hourlyParams, setHourlyParams] = useState(defaultHourlyParamsState);
  const [hourlyDisplay, setHourlyDisplay] = useState(false);
  const [datePicker, setDatePicker] = useState(defaultDatePicker);
  const [achievedOnly, setAchievedOnly] = useState(false);

  const { data: dailySnData, isFetching: dailySnDataFecthing } =
    useGetDeviceAvailabilityReportDailyByUsernameV2Query(params, { skip: !sn });

  const [
    getHourlyDeviceAvailability,
    { data: hourlySnData, isLoading: hourlySnDataFetching },
  ] = useGetavailabilityDetailMutation();

  useEffect(() => {
    setSelectedSn(sn);
  }, [sn]);

  const onChangeSn = (e) => {
    const newSn = e.value;
    setSelectedSn(newSn);

    if (hourlyDisplay) {
      getHourlyDeviceAvailability({
        params: {
          ...hourlyParams,
          status: downStatusOnly ? "down" : undefined,
          timeGroupping: timeGroup,
        },
        body: newSn,
      });
    } else {
      setParams((prevParams) => ({
        ...prevParams,
        snsAsString: newSn,
      }));
    }
  };

  const transformSn = snList.map((sn) => ({ value: sn, label: sn }));

  const handleClick = useCallback(
    (time) => {
      setHourlyParams((prevParams) => ({
        ...prevParams,
        endTs: moment(time).endOf("day").valueOf(),
        startTs: moment(time).startOf("day").valueOf(),
        timeGroupping: timeGroup,
      }));
      setHourlyDisplay(true);
      getHourlyDeviceAvailability({
        params: {
          ...hourlyParams,
          endTs: moment(time).endOf("day").valueOf(),
          startTs: moment(time).startOf("day").valueOf(),
          status: downStatusOnly ? "down" : undefined,
          timeGroupping: timeGroup,
        },
        body: selectedSn,
      });
    },
    [
      downStatusOnly,
      getHourlyDeviceAvailability,
      hourlyParams,
      selectedSn,
      timeGroup,
    ]
  );

  const columns = useMemo(() => {
    return [
      {
        name: !hourlyDisplay ? "Date" : "Time",
        selector: (row) => row.strTime,
        cell: ({ strTime }) =>
          !hourlyDisplay ? (
            strTime ?
            <strong
              style={{ color: "#2065AC", cursor: "pointer" }}
              onClick={() => handleClick(strTime)}
            >
              {moment(strTime).format(`DD/MM/YYYY`)}
            </strong> 
            :
            strTime
          ) : (
            moment(strTime).format(`HH:mm`)
          ),
        sortable: true,
      },
      {
        name: "Uptime",
        selector: (row) => row.totalUptimeFormatted,
        sortable: true,
        omit: hourlyDisplay,
      },
      {
        name: "Availability",
        selector: (row) => row?.sla,
        sortable: true,
        cell: ({ sla, customerSla }) => (
          <span
            style={{
              color: customerSla > sla ? "#F5365C" : "inherit",
              fontWeight: customerSla > sla ? "bold" : "normal",
            }}
          >
            {(sla * 100).toFixed(2)}%
          </span>
        ),
        omit: hourlyDisplay,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        omit: !hourlyDisplay,
        cell: ({ status }) => (
          <span
            style={{
              color: status === "DOWN" ? "#F5365C" : "inherit",
            }}
          >
            {status}
          </span>
        ),
      },
    ];
  }, [handleClick, hourlyDisplay]);

  const paginationComponentOptions = {
    noRowsPerPage: true,
  };

  const onDateChange = (start, end, label) => {
    setDatePicker({
      startTime: start,
      endTime: end,
      label: label,
    });
    setParams((prevParams) => ({
      ...prevParams,
      startTs: start.toDate().getTime(),
      endTs: end.toDate().getTime(),
    }));
  };

  const handleChangeAvailabilityFilter = () => {
    const newParam = achievedOnly;
    setAchievedOnly(!newParam);

    setParams((prevParams) => ({
      ...prevParams,
      availability: newParam ? undefined : "not_achieved",
    }));
  };

  const handleChangeStatusFilter = () => {
    const newParam = downStatusOnly;
    setDownStatusOnly(!newParam);

    getHourlyDeviceAvailability({
      params: {
        ...hourlyParams,
        status: !newParam ? "down" : undefined,
        timeGroupping: timeGroup,
      },
      body: selectedSn,
    });
  };

  const timeGroupOpts = [
    { label: "3m", value: "3m" },
    { label: "5m", value: "5m" },
    { label: "15m", value: "15m" },
    { label: "30m", value: "30m" },
    { label: "60m", value: "60m" },
  ];

  const onChangeTimeGroup = (params) => {
    setTimeGroup(params.value);
    getHourlyDeviceAvailability({
      params: {
        ...hourlyParams,
        timeGroupping: params.value,
        status: downStatusOnly ? "down": undefined
      },
      body: selectedSn,
    });
  };

  const handleBacktoDateList = () => {
    setHourlyDisplay(false);
    setParams((prevParams) => ({
      ...prevParams,
      snsAsString: selectedSn,
    }));
  };

  const renderModalHeader = () => {
    let textDate
    if (dateFilter.endTime.diff(dateFilter.startTime, 'days') <= 1) {
      textDate = `${dateFilter.startTime.format('DD MMM YYYY')}`;
    } else {
      textDate = `${dateFilter.startTime.format('DD MMM YYYY')} - ${dateFilter.endTime.format('DD MMM YYYY')}`;
    }
    if (!hourlyDisplay) {
      return (
        <Row className="mb-3 d-flex justify-content-between align-items-center">
          <Col className="d-flex justify-content-between align-items-center">
            <span>
              Showing data for {textDate}
            </span>
            <div hidden>
              <BootstrapDateRangePicker
                onDateChange={onDateChange}
                startDate={datePicker.startTime}
                endDate={datePicker.endTime}
                label={datePicker.label}
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-end">
            <InputSwitch
              style={{ transform: "scale(0.75)" }}
              checked={achievedOnly}
              onChange={handleChangeAvailabilityFilter}
            />
            <span>Show only not achieved</span>
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <Row className="mb-2 d-flex justify-content-between align-items-center">
            <Col
              md="5"
              className="d-flex justify-content-between align-items-center"
            >
              <Button
                color="link"
                onClick={handleBacktoDateList}
                className="d-flex align-items-center p-0"
              >
                <i className="fas fa-chevron-left"></i>
                <span>{textDate}</span>
              </Button>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <InputSwitch
                style={{ transform: "scale(0.75)", marginRight: "0.5rem" }}
                checked={downStatusOnly}
                onChange={handleChangeStatusFilter}
              />
              <span style={{ marginRight: "1rem" }}>Show only down status</span>
              <PrimeDropdown
                className="col-lg-3 col-sm-3"
                options={timeGroupOpts}
                value={timeGroup}
                onChange={onChangeTimeGroup}
              />
            </Col>
          </Row>
          <Row className="mb-2 d-flex justify-content-between align-items-center">
            <Col>
              <ReportHeatmapEchart params={hourlyParams} selectedSn={selectedSn} timeGroup={timeGroup}/>
            </Col>
          </Row>
        </>
      );
    }
  };

  const isSameDay = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const mapDataTableForExport = (data) => {
    const dailyFields = ["strTime", "totalUptimeFormatted", "sla"];
    const hourlyFields = ["strTime", "status"];
    const fields = hourlyDisplay ? hourlyFields : dailyFields;
    const fieldReplacements = {
      strTime: "Date",
      totalUptimeFormatted: "Uptime",
      sla: "Availability",
      status: "Status",
    };

    const fieldFormats = {
      strTime: (value) => {
        if (hourlyDisplay) {
          return moment(value).format(`DD/MM/YYYY HH:mm:ss`);
        } else {
          return moment(value).format(`DD/MM/YYYY`);
        }
      },
      sla: (value) => (value * 100).toFixed(2) + "%",
    };

    return {
      head: [fields.map((field) => fieldReplacements[field] || field)],
      body: data.map((item) =>
        fields.map((field) =>
          fieldFormats[field] ? fieldFormats[field](item[field]) : item[field]
        )
      ),
    };
  };

  const handleExport = () => {
    const reportTitle = `Report Availability_${selectedSn} Details`;

    let textDate = "";

    const formatDate = (timestamp) => moment(timestamp).format("DD MMM YYYY");

    if (isSameDay(params.startTs, params.endTs) || hourlyDisplay) {
      textDate = formatDate(
        hourlyDisplay ? hourlyParams.startTs : params.startTs
      );
    } else {
      textDate = `${formatDate(params.startTs)} - ${formatDate(params.endTs)}`;
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const { head, body } = mapDataTableForExport(
      hourlyDisplay ? hourlySnData : dailySnData
    );

    // Create a worksheet and add the headers first
    const ws = XLSX.utils.aoa_to_sheet([head[0], ...body]);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, reportTitle + `_${textDate}` + fileExtension);
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isSnModalOpen}
      toggle={toggleSnModal}
    >
      <div className="modal-header d-flex align-items-center pb-2">
        <h3 className="modal-title">Device’s Availability Details</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleSnModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body py-2">
        <Row className="mb-2">
          <Col>
            <PrimeDropdown
              options={transformSn}
              value={selectedSn}
              onChange={onChangeSn}
            />
          </Col>
        </Row>
        {renderModalHeader()}
        <DataTable
          title=""
          keyField="id"
          data={hourlyDisplay ? hourlySnData : dailySnData}
          columns={columns}
          defaultSortField="strTime"
          striped
          noHeader
          highlightOnHover
          persistTableHead
          progressPending={hourlySnDataFetching || dailySnDataFecthing}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          customStyles={customStyles3}
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
      <div className="modal-footer pt-0">
        <Button
          className="btn btn-default px-5 py-1 ml-auto w-100"
          disabled={hourlySnDataFetching || dailySnDataFecthing}
          onClick={handleExport}
        >
          Export
        </Button>
      </div>
    </Modal>
  );
};

export default DeviceAvailabilityDetailModal;
