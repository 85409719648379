import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { paginationRowsPerPageOptions } from "utils";
import { customStyles3 } from "utils/datatableStyles";
import RestartLogEChart from "./RestartLogEChart";
import { BootstrapDateRangePicker } from "components";
import moment from "moment";
import Swal from "sweetalert2";
import { useGetDeviceUptimeHistoryMutation } from "features/device/service/usageApi";

const RestartLogModal = ({ isRestartLogModalOpen, toogleRestartLogModal, sn }) => {
  const [startTs, setStartTs] = useState(moment().subtract(6, "days").startOf("day"));
  const [endTs, setEndTs] = useState(moment().endOf("day"));
  const [labelTs, setLabelTs] = useState("Last 7 Days");
  
  const [getRestartLog, {data: restartLogData, isLoading: restartLogDataLoading}] = useGetDeviceUptimeHistoryMutation();
 
  useEffect(() => {
    if (isRestartLogModalOpen && sn) {
      getRestartLog({
        params: {
          startTs: getTs(startTs),
          endTs: getTs(endTs),
          serialNumber: sn,
        },
      });
    }
  }, [endTs, getRestartLog, isRestartLogModalOpen, sn, startTs]);
  
  const getTs = (date) => {
    const ts = moment(`${date}`, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").valueOf()
    return ts
  }
  const onDateChange = (start, end, label) => {
    const startDate = new Date(start);
    let endDate = new Date(end);
    const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

    if (diffInDays < 6) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Date Range",
        text: "The selected date range must be 1 week.",
      }).then(() => {
        endDate = moment(startDate).add(6, "days").endOf("day");
        setStartTs(startDate);
        setEndTs(endDate);
        setLabelTs("Custom");
      });
    } else {
      setStartTs(moment(startDate).startOf("day"));
      setEndTs(moment(endDate).endOf("day"));
      setLabelTs(label);
    }
  };

  const options = {
    ranges: {
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "This Week": [moment().startOf("week"), moment().endOf("week")],
      "Previous Week": [
        moment().subtract(1, "week").startOf("week"),
        moment().subtract(1, "week").endOf("week"),
      ],
    },
    locale: {
      format: "MM/DD/YYYY",
      separator: " - ",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      fromLabel: "From",
      toLabel: "To",
      customRangeLabel: "Custom",
      weekLabel: "W",
      daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      firstDay: 1,
    },
    alwaysShowCalendars: true,
    startDate: startTs,
    endDate: endTs,
    drops: "bottom",
    cancelClass: "btn-secondary",
    dateLimit: { days: 6 },
  };

  const formatDate = (timestamp) => moment(timestamp).format("DD MMM YYYY");

  const renderModalHeader = () => {
    return (
      <>
        <Row className="mb-2 d-flex justify-content-between align-items-center">
          <Col
            md="5"
            className="d-flex justify-content-between align-items-center text-muted"
            style={{ fontWeight: "500" }}
          >
            Trend in{" "}
            {labelTs === "Custom"
              ? `${formatDate(startTs)} - ${formatDate(endTs)}`
              : labelTs}
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <div style={{ width: "13rem" }}>
              <BootstrapDateRangePicker
                onDateChange={onDateChange}
                options={options}
                startDate={startTs}
                endDate={endTs}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2 d-flex justify-content-between align-items-center">
          <Col>
            <RestartLogEChart data={restartLogData?.chart} isLoading={restartLogDataLoading}/>
          </Col>
        </Row>
      </>
    );
  };

  const formatDuration = (seconds) => {
    return moment.duration(seconds, "seconds").humanize();
  };

  const columns = [
    {
      name: "RESTART TIME",
      selector: (row) => moment(row.strTime).format("DD-MM-YYYY HH:mm"),
      sortable: true,
    },
    {
      name: "UPTIME BEFORE NEXT RESTART",
      selector: (row) => formatDuration(parseInt(row.uptimeDurationSeconds)),
      sortable: true,
    },
  ];

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isRestartLogModalOpen}
      toggle={toogleRestartLogModal}
    >
      <div className="modal-header d-flex align-items-center pb-0">
        <h3 className="modal-title">Restart Log</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toogleRestartLogModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {renderModalHeader()}
        <div className="text-muted mb-2" style={{ fontWeight: "500" }}>
          Details
        </div>
        <DataTable
          title="Details"
          keyField="id"
          data={restartLogData?.table}
          columns={columns}
          defaultSortField="strTime"
          striped
          noHeader
          highlightOnHover
          persistTableHead
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          customStyles={customStyles3}
          paginationComponentOptions={{ noRowsPerPage: true }}
          progressPending={restartLogDataLoading}
        />
      </div>
    </Modal>
  );
};

export default RestartLogModal;
