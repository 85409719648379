import { defaultOptions, usePagination } from "hooks";
import { useGetInventoryCustomerDevicesQuery } from "../services";
import { useMemo } from "react";

const defaultParams = {
  customerId: undefined,
};

export function useInventoryCustomerDevicesDatatable(
  params = defaultParams,
  options = defaultOptions
) {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination({
    pageNumber: 1,
    pageSize: 10,
    sortFields: options.sortFields,
    sortOrder: options.sortOrder,
  });

  const { customerId } = params;

  const { data: paginatedResult, isLoading } =
    useGetInventoryCustomerDevicesQuery(
      {
        pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
        pageSize,
        sortFields: sortFields ?? undefined,
        sortOrder: sortOrder ?? undefined,
        customerId: customerId,
      },
      { skip: !customerId }
    );

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
