import React, { useMemo } from "react";
import { TritronikInputText, TritronikSelect } from "components";
import { Formik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { Button, Card, CardBody, Col, FormText, Row } from "reactstrap";
import FormItem from "./../FormItem";
import FormItemPlaceholder from "./../FormItemPlaceholder";
import { useSdwanReference } from "./../../hooks/useSdwanReference";
import { history } from "utils";
import * as Yup from "yup";

const InterfaceConfigurationForm = ({
  data,
  onSubmitInterface,
  viewMode = false,
}) => {
  const { options: ipv4ConfigTypeOptions } =
    useSdwanReference("ipv4-conf-types");
  const { options: ipv4GwRuleOptions } =
    useSdwanReference("ipv4-gateway-rules");
  const { options: ipv6ConfigTypeOptions } =
    useSdwanReference("ipv6-conf-types");
  const { options: ipv6GwRuleOptions } =
    useSdwanReference("ipv6-gateway-rules");
  const initialValues = useMemo(() => {
    return {
      descr: data?.descr ?? "",
      dhcp6usev4iface: data?.dhcp6usev4iface ?? false,
      enable: data?.enable ?? false,
      gateway: data?.gateway ?? "",
      gatewayv6: data?.gatewayv6 ?? "",
      ipaddr: data?.ipaddr ?? "",
      ipaddrv6: data?.ipaddrv6 ?? "",
      lock: data?.lock ?? false,
      mtu: data?.mtu ?? "",
      staticv6usev4iface: data?.staticv6usev4iface ?? false,
      subnet: data?.subnet ?? "",
      subnetv6: data?.subnetv6 ?? "",
      type: data?.type ?? "",
      type6: data?.type6 ?? "",
      networkDeviceName: data?.device_name ?? "",
      identifier: data?.identifier ?? "",
    };
  }, [data]);

  const onCancel = () => {
    history.goBack();
  };

  const validationSchema = Yup.object().shape({
    descr: Yup.string().required("Interface Name is required"),
    mtu: Yup.number()
      .typeError("MTU must be a number")
      .positive("MTU must be positive")
      .integer("MTU must be an integer"),
    type: Yup.string().required("IPv4 Configuration Type is required"),
    ipaddr: Yup.string().matches(
      /^(\d{1,3}\.){3}\d{1,3}$/,
      "IPv4 Address must be a valid IP format"
    ),
    subnet: Yup.number()
      .typeError("Subnet must be a number")
      .min(0, "Subnet must be between 0 and 32")
      .max(32, "Subnet must be between 0 and 32"),
    type6: Yup.string().required("IPv6 Configuration Type is required"),
    ipaddrv6: Yup.string().matches(
      /^([0-9a-fA-F]{0,4}:){1,7}[0-9a-fA-F]{0,4}$/,
      "IPv6 Address must be a valid format"
    ),
    subnetv6: Yup.number()
      .typeError("Subnet must be a number")
      .min(0, "Subnet must be between 0 and 128")
      .max(128, "Subnet must be between 0 and 128"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitInterface}
      validationSchema={validationSchema}
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => {
        return (
          <div className="position-relative">
            <Card className="mb-3">
              <CardBody>
                <Row>
                  <Col md="3">
                    <h3 className="mt-md-2 font-weight-medium">
                      General Configuration
                    </h3>
                  </Col>
                  <Col md="9">
                    <FormItem label="Enable">
                      <InputSwitch
                        checked={values.enable}
                        onChange={(e) => setFieldValue("enable", e.value)}
                        disabled={viewMode}
                      />
                    </FormItem>
                    <FormItem label="Interface Name">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.descr} />
                      ) : (
                        <TritronikInputText name="descr" small />
                      )}
                    </FormItem>
                    <FormItem label="Prevent Interface Removal">
                      <InputSwitch
                        checked={values.lock}
                        onChange={(e) => setFieldValue("lock", e.value)}
                        disabled={viewMode}
                      />
                    </FormItem>
                    <FormItem label="Identifier">
                      <FormItemPlaceholder
                        value={values.identifier}
                        helperText="The internal configuration identifier of this interface."
                      />
                    </FormItem>
                    <FormItem label="Device">
                      <FormItemPlaceholder
                        value={values.networkDeviceName}
                        helperText="The assigned network device name of this interface."
                      />
                    </FormItem>
                    <FormItem label="MTU">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.mtu} />
                      ) : (
                        <TritronikInputText
                          name="mtu"
                          small
                          helperText="If you leave this field blank, the adapter's default MTU will be used. This is typically 1500 bytes but can vary in some circumstances."
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mb-3">
              <CardBody>
                <Row>
                  <Col md="3" sm="12">
                    <h3 className="mt-md-2 font-weight-medium">
                      IPv4 Configuration
                    </h3>
                  </Col>
                  <Col md="9" sm="12">
                    <FormItem label="IPv4 Configuration Type">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.type} />
                      ) : (
                        <TritronikSelect
                          value={values.type}
                          name="type"
                          options={ipv4ConfigTypeOptions}
                          onChange={(e) => {
                            const selectedType = e.value;
                            setFieldValue("type", selectedType);
                            // Reset specific fields based on selected type
                            if (
                              selectedType === "none" ||
                              selectedType === "dhcp"
                            ) {
                              setFieldValue("ipaddr", initialValues.ipaddr);
                              setFieldValue("subnet", initialValues.subnet);
                              setFieldValue("gateway", initialValues.gateway);
                            }
                          }}
                          invalid={touched.type && errors.type}
                          error={errors.type}
                        />
                      )}
                    </FormItem>
                    <FormItem label="IPv4 Address">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.ipaddr} />
                      ) : (
                        <div
                          className="d-flex flex-1 align-items-center w-100"
                          style={{ columnGap: 8 }}
                        >
                          <TritronikInputText
                            name="ipaddr"
                            small
                            disabled={
                              values.type === "none" || values.type === "dhcp"
                            }
                          />
                          <TritronikInputText
                            size="sm"
                            type="number"
                            name="subnet"
                            block={false}
                            style={{ width: 80 }}
                            disabled={
                              values.type === "none" || values.type === "dhcp"
                            }
                          />
                        </div>
                      )}
                    </FormItem>
                    <FormItem label="IPv4 Gateways Rules">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.gateway} />
                      ) : (
                        <TritronikSelect
                          value={values.gateway}
                          options={ipv4GwRuleOptions}
                          name="gateway"
                          onChange={(e) => setFieldValue("gateway", e.value)}
                          disabled={values.type === "none"}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card style={{ marginBottom: "7rem" }}>
              <CardBody>
                <Row>
                  <Col md="3" sm="12">
                    <h3 className="mt-md-2 font-weight-medium">
                      IPv6 Configuration
                    </h3>
                  </Col>
                  <Col md="9" sm="12">
                    <FormItem label="IPv6 Configuration Type">
                      {viewMode ? (
                        <FormItemPlaceholder value={!values.type6 || values.type6 === "" ? "None" : values.type6} />
                      ) : (
                        <TritronikSelect
                          name="type6"
                          value={values.type6}
                          options={ipv6ConfigTypeOptions}
                          // onChange={(e) => setFieldValue("type6", e.value)}
                          onChange={(e) => {
                            const selectedType = e.value;
                            setFieldValue("type6", selectedType);
                            // Reset specific fields based on selected type
                            if (
                              selectedType === "none" ||
                              selectedType === "dhcp6"
                            ) {
                              setFieldValue("ipaddrv6", initialValues.ipaddrv6);
                              setFieldValue("subnetv6", initialValues.subnetv6);
                              setFieldValue(
                                "gatewayv6",
                                initialValues.gatewayv6
                              );
                            }
                          }}
                          invalid={touched.type6 && errors.type6}
                          error={errors.type6}
                        />
                      )}
                    </FormItem>
                    <FormItem label="IPv6 Address">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.ipaddrv6} />
                      ) : (
                        <div
                          className="d-flex flex-1 align-items-center w-100"
                          style={{ columnGap: 8 }}
                        >
                          <TritronikInputText
                            name="ipaddrv6"
                            className="w-75"
                            small
                            disabled={
                              values.type6 === "none" ||
                              values.type6 === "dhcp6"
                            }
                          />
                          <TritronikInputText
                            size="sm"
                            type="number"
                            name="subnetv6"
                            block={false}
                            style={{ width: 80 }}
                            disabled={
                              values.type6 === "none" ||
                              values.type6 === "dhcp6"
                            }
                          />
                        </div>
                      )}
                    </FormItem>
                    <FormItem label="IPv6 Gateways Rules">
                      {viewMode ? (
                        <FormItemPlaceholder value={values.gatewayv6} />
                      ) : (
                        <TritronikSelect
                          name="gatewayv6"
                          value={values.gatewayv6}
                          options={ipv6GwRuleOptions}
                          onChange={(e) => setFieldValue("gatewayv6", e.value)}
                          disabled={values.type6 === "none"}
                        />
                      )}
                    </FormItem>
                    <div hidden>
                      <FormItem label="Use IPv6 Connectivity">
                        <div>
                          <InputSwitch
                            checked={values.staticv6usev4iface}
                            onChange={(e) =>
                              setFieldValue("staticv6usev4iface", e.value)
                            }
                            disabled={viewMode}
                          />
                          <FormText color="muted">
                            Set the IPv6 address on the IPv4 PPP connectivity
                            link.
                          </FormText>
                        </div>
                      </FormItem>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {!viewMode ? (
              <Card
                className="mb-0 position-fixed rounded-0"
                style={{ padding: "0px 30px", bottom: 0, left: 0, right: 0 }}
              >
                <CardBody
                  className="d-flex justify-content-end"
                  style={{ gap: 8 }}
                >
                  <Button
                    color="secondary"
                    size="sm"
                    className="px-5"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    className="px-5"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </CardBody>
              </Card>
            ) : null}
          </div>
        );
      }}
    </Formik>
  );
};

export default InterfaceConfigurationForm;
