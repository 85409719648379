import { FullscreenLoading, PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import GatewayConfigurationForm from "../component/Form/GatewayConfigurationForm";
import { useParams } from "react-router";
import { useGetDeviceGatewayByIdQuery } from "../services";
import { useSdwanReference } from "./../hooks/useSdwanReference";
import { useUpdateGateway } from "../hooks/useUpdateGateway";
import { toast } from "react-toastify";
import { history } from "utils";

const EditGatewayConfiguration = () => {
  const { gatewayId, sn } = useParams();
  
  const { data, isLoading } = useGetDeviceGatewayByIdQuery({gatewayId, sn}, {
    skip: !gatewayId,
  });
  const { options: interfaceOptions } = useSdwanReference("list-interfaces", sn);
  const { options: ipFamilyOptions } = useSdwanReference("ip-family");
  const { updateGateway, isLoading: isUpdating } = useUpdateGateway(sn);

  const onUpdate = async (values) => {
    delete values.name;

    if (data && data.uuid) {
      try {
        await updateGateway(data.uuid, values);
        toast.success("Command to update Gateway sent.");
        history.goBack();
      } catch (err) {
        toast.error(err.data.message || "Failed to update Gateway.");
      }
    }
  };
  
  const title = useMemo(() => {
    if (!data?.name) return "Configuration";
    return `${data?.name} Configuration`;
  }, [data]);
  
  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} pageTitle={sn} />
      {isLoading ? (
        <FullscreenLoading />
      ) : (
        <GatewayConfigurationForm
          data={data}
          interfaceOptions={interfaceOptions}
          ipFamilyOptions={ipFamilyOptions}
          onSubmit={onUpdate}
          isLoading={isUpdating}
        />
      )}
    </PageContent>
  );
};

export default EditGatewayConfiguration;
