export const chartColors = [
  "#2065AC",
  "#A3C1DA",
  "#0E4C8A",
  "#F2A900",
  "#D9534F",
  "#5BC0EB",
  "#FFCC00",
  "#FF5733",
  "#33FF57",
  "#FFC300",
  "#DAF7A6",
  "#FF33A1",
  "#33A1FF",
  "#FF6347",
  "#40E0D0",
  "#FFD700",
  "#8A2BE2",
  "#228B22",
  "#FF4500",
  "#00CED1",
  "#FF69B4",
  "#1E90FF",
  "#FF8C00",
  "#2F4F4F",
  "#9400D3",
  "#00FA9A",
  "#ADD8E6",
  "#FF1493",
  "#00BFFF",
  "#8B008B",
  "#FFDAB9",
  "#20B2AA",
  "#87CEFA",
  "#778899",
  "#B22222",
  "#2E8B57",
  "#FF6347",
  "#4682B4",
  "#D2691E",
  "#FF7F50",
  "#6495ED",
  "#DC143C",
  "#00FFFF",
  "#0000CD",
  "#556B2F",
  "#FF4040",
  "#8A2BE2",
  "#483D8B",
  "#FF69B4",
  "#4169E1",
  "#FFD700",
  "#2F4F4F",
  "#FF6347",
  "#4682B4",
  "#D2691E",
  "#FF7F50",
  "#6495ED",
  "#DC143C",
  "#00FFFF",
  "#0000CD",
];
