import React, { useMemo } from "react";
import { MegaMenu } from "primereact/megamenu";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";
import "./PageToolbar.css";
import { history } from "utils";
import { useParams } from "react-router";
import { useGetSdwanDevice } from "../hooks/useGetSdwanDevice";

const PageToolbar = ({ title, className, showHome = true, pageTitle }) => {
  const { sn } = useParams();

  const { data } = useGetSdwanDevice(sn);

  const menuItems = useMemo(() => {
    const interfaceItems = [];
    if (data && data.interfaces && data.interfaces.length > 0) {
      const menus = data.interfaces
        .filter(
          ({ name, networkDeviceName }) =>
            name &&
            networkDeviceName &&
            !(
              networkDeviceName.toLowerCase().includes("ppp") ||
              ["lo0"].includes(networkDeviceName)
            )
        )
        .map(({ id, description, networkDeviceName, name }) => {
          return {
            label: `${description} (${name})`,
            command: () => {
              if (
                networkDeviceName &&
                networkDeviceName.toLowerCase().includes("ppp")
              ) {
                // history.push(
                //   `/admin/sdwan/device/${sn}/interfaces/${id}/4g-configuration`
                // )
              } else {
                history.push(
                  `/admin/sdwan/device/${sn}/interfaces/${name}/configuration`
                );
              }
            },
          };
        });

      interfaceItems.push(...menus);
    }

    return [
      {
        label: "Overview",
        command: () => history.push(`/admin/sdwan/device/${sn}/overview`),
      },
      {
        label: "Interface",
        items: [
          [
            {
              label: "General",
              items: [
                {
                  label: "Overview",
                  command: () =>
                    history.push(`/admin/sdwan/device/${sn}/interfaces`),
                },
              ],
            },
          ],
          [
            {
              label: "Configuration",
              items: interfaceItems,
            },
          ],
        ],
      },
      {
        label: "Gateway",
        items: [
          [
            {
              label: "",
              className: "d-none",
              items: [
                {
                  label: "Configuration",
                  command: () =>
                    history.replace(
                      `/admin/sdwan/device/${sn}/gateways/configurations`
                    ),
                },
                {
                  label: "Group",
                  command: () =>
                    history.replace(
                      `/admin/sdwan/device/${sn}/gateways/groups`
                    ),
                },
              ],
            },
          ],
        ],
      },
      {
        label: "Firewall",
        items: [
          [
            {
              label: "",
              className: "d-none",
              items: [
                {
                  label: "Rules",
                  command: () =>
                    history.push(`/admin/sdwan/device/${sn}/firewall/rules`),
                },
                {
                  label: "Aliases",
                  command: () =>
                    history.push(`/admin/sdwan/device/${sn}/firewall/aliases`),
                },
              ],
            },
          ],
        ],
      },
    ];
  }, [data, sn]);

  return (
    <Card className={className}>
      <CardHeader>
        <div className="d-flex" style={{ columnGap: 8 }}>
          {showHome && (
            <div className="d-flex align-items-center">
              <a
                href={`#/admin/sdwan/device`}
                style={{ marginBottom: 0 }}
                className="text-sm text-default"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/admin/sdwan/device');
                }}
              >
                Device List
              </a>
            </div>
          )}
          <div className="d-flex align-items-center p-0">
            <i
              style={{ fontSize: 14 }}
              className="pi pi-chevron-right text-gray"
            />
          </div>
          <div className="d-flex align-items-center">
            <span style={{ marginBottom: 0 }} className="text-sm text-muted">
              {title}
            </span>
          </div>
        </div>
        {pageTitle && <h2 className="mb-0">{pageTitle}</h2>}
      </CardHeader>
      <CardBody className="py-2">
        <MegaMenu className="sdwan-toolbar" model={menuItems} />
      </CardBody>
    </Card>
  );
};

PageToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PageToolbar;
