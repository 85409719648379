import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Card } from "reactstrap";
import { useInventoryCustomerDevicesDatatable } from "../hooks/useInventoryCustomerDevicesDatatable";
import { customStyles } from "utils/datatableStyles";
import { history, paginationRowsPerPageOptions } from "utils";
import { formatDateFromNowColumn } from "utils/datatablesColumn";

const CustomerRowDetail = ({ data }) => {
  const { name, id } = data;
  const dt = useInventoryCustomerDevicesDatatable({ customerId: id });

  const renderStatus = (row) => {
    const { connectionStatus, isSynchronized } = row;
    const color = !isSynchronized
      ? "#8898AA"
      : connectionStatus === "Connected"
      ? "#2DCE89"
      : "#F5365C";
    const text = !isSynchronized
      ? "Unsynchronized"
      : connectionStatus === "Connected"
      ? "Online"
      : "Offline";

    return (
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="px-3 py-1 rounded"
      >
        <i className="fa fa-check-circle mr-1" style={{ color }} />
        <span>{text}</span>
      </div>
    );
  };

  const columns = useMemo(() => {
    return [
      {
        name: "SERIAL NUMBER",
        selector: (row) => row?.sn,
        sortable: false,
        sortField: "sn",
        left: true,
        cell: ({ sn }) => {
          if (!sn) return "-";

          return (
            <a
              className="text-sm text-default"
              href="#device"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/sdwan/device/${sn}/overview`);
              }}
            >
              {sn}
            </a>
          );
        },
      },
      {
        name: "DEVICE STATUS",
        selector: (row) => row?.connectionStatus,
        sortable: false,
        sortField: "connectionStatus",
        left: true,
        cell: renderStatus,
      },
      {
        name: "LAST UPDATED",
        selector: (row) => row?.updateModified,
        sortable: false,
        sortField: "updateModified",
        left: true,
        cell: ({ updateModified }) => formatDateFromNowColumn(updateModified),
      },
    ];
  }, []);

  return (
    <Card style={{ padding: "1.5rem" }}>
      <h2 style={{ fontSize: "1rem", fontWeight: 600, lineHeight: "1.5rem" }}>
        {name} Device List
      </h2>
      <DataTable
        data={dt.data ?? []}
        columns={columns}
        defaultSortField="name"
        keyField="id"
        striped
        noHeader
        customStyles={{
          ...customStyles,
          tableWrapper: {
            style: {
              minHeight: null,
            },
          },
        }}
        persistTableHead
        pagination
        paginationServer
        progressPending={dt.isLoading}
        paginationTotalRows={dt.totalRows}
        onChangeRowsPerPage={dt.onChangeRowsPerPage}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationPerPage={dt.pageSize}
        onChangePage={dt.onChangePage}
        sortServer
        onSort={dt.onSort}
      />
    </Card>
  );
};

export default CustomerRowDetail;
