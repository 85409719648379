import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap-daterangepicker/daterangepicker.css";

const dateRangeOptions = {
  ranges: {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "This Week": [moment().startOf("week"), moment().endOf("week")],
    "Previous Week": [
      moment().subtract(1, "week").startOf("week"),
      moment().subtract(1, "week").endOf("week"),
    ],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Previous Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Year": [moment().startOf("year"), moment().endOf("year")],
    "Previous Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  },
  locale: {
    format: "MM/DD/YYYY",
    separator: " - ",
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    fromLabel: "From",
    toLabel: "To",
    customRangeLabel: "Custom",
    weekLabel: "W",
    daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    firstDay: 1,
  },
  alwaysShowCalendars: true,
  startDate: moment(),
  endDate: moment(),
  drops: "bottom",
  cancelClass: "btn-secondary",
};

const BootstrapDateRangePicker = ({
  onDateChange,
  alwaysShowCalendars = true,
  options = dateRangeOptions,
  startDate,
  endDate,
  label: labelFromProps
}) => {
  const [label, setLabel] = useState(labelFromProps ?? `${Object.keys(options.ranges)[0]}`);
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const onCallback = (start, end, label) => {
    setLabel(label);
    if (typeof onDateChange === "function") {
      onDateChange(start, end, label);
    }
  };

  useEffect(() => {
    if (ref.current && startDate && endDate) {
      ref.current.setStartDate(startDate);
      ref.current.setEndDate(endDate);
    }
  }, [endDate, startDate]);

  return (
    <DateRangePicker
      ref={ref}
      initialSettings={{
        ...options,
        alwaysShowCalendars: alwaysShowCalendars,
        showDropdowns: true,
      }}
      onShow={() => setShow(true)}
      onHide={() => setShow(false)}
      onCallback={onCallback}
    >
      <button
        className="btn btn-icon btn-white btn-block text-default"
        type="button"
      >
        <span className="btn-inner--icon">
          <i className="far fa-clock" />
        </span>
        <span className="btn-inner--text">{labelFromProps ?? label}</span>
        <span className="btn-inner--icon">
          <i className={`fa fa-chevron-${show ? "up" : "down"}`} />
        </span>
      </button>
    </DateRangePicker>
  );
};

export default BootstrapDateRangePicker;
