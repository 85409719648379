import { PageContent } from "components";
import React from "react";
import PageToolbar from "../component/PageToolbar";
import { useSdwanReference } from "../hooks/useSdwanReference";
import { toast } from "react-toastify";
import { history } from "utils";
import { useParams } from "react-router";
import { useGetDeviceFirewallRuleByIdQuery } from "../services";
import FirewallRuleForm from "../component/Form/FirewallRuleForm";
import { useUpdateFirewallRule } from "../hooks/useUpdateFirewallRules";

const EditFirewallRuleConfiguration = () => {
  const { sn, uuid } = useParams();
  const { data, isLoading } = useGetDeviceFirewallRuleByIdQuery({ sn, uuid }, { skip: !sn && !uuid });
  const { options: typeOptions } = useSdwanReference("fw-alias-type");
  const { options: protocolOptions } = useSdwanReference("list-protocols");
    const { options: interfaceOptions } = useSdwanReference("list-all-interfaces", sn);
    const { options: gatewayOptions } = useSdwanReference("list-gateways", sn);
    const { options: hostOptions } = useSdwanReference("list-host-network", sn);
    const { options: portOptions } = useSdwanReference("list-port-network", sn);
  const { update, isLoadingMutation } = useUpdateFirewallRule(sn, uuid);

  const onUpdate = async (values) => {
    
    try {
      await update(values);
      toast.success("Command to update Firewall Rule sent.");
      history.goBack();
    } catch (err) {
      toast.error(err.data.message || "Failed to update Firewall Rule.");
    }
  };

  const mappedPortOptions = [{label: 'Any', value: 'any'}, {label: 'Other', value: 'other'}, ...portOptions]

  const mappedHostOptions =  hostOptions.flatMap(category => 
    category.items.map(item => ({
      label: item.label,
      value: item.value,
      category: category.label
    }))
  );

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title="Update Firewall Rule" pageTitle={uuid} />
      <FirewallRuleForm
        data={data}
        typeOptions={typeOptions}
        onSubmit={onUpdate}
        isLoading={isLoading || isLoadingMutation}
        interfaceOptions={interfaceOptions}
        protocolOptions={protocolOptions}
        gatewayOptions={gatewayOptions}
        hostOptions={mappedHostOptions}
        portOptions={mappedPortOptions}
      />
    </PageContent>
  );
};

export default EditFirewallRuleConfiguration;
