import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { unknown } from "./../../../utils";
import styled from "styled-components";
import { Tooltip } from "primereact/tooltip";

const getColor = (status) => {
  switch (status) {
    case "Excellent":
      return "#6ACE61";
    case "Good":
      return "#ffd600";
    case "Fair":
      return "#fb6340";
    case "Poor":
      return "#f5365c";
    default:
      return "#AB0312";
  }
};
const Value = styled.span`
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
`;

Value.defaultProps = {
  color: "#2065AC",
};
const getValueByStatus = (status) => {
  const values = {
    Excellent: '> -65 Dbm',
    Good: '-74 to -65 Dbm',
    Fair: '-84 to -75 Dbm',
    Poor: '-94 to -85 Dbm',
    "No Signal": '≤ -95 Dbm',
    "Check Required": "No Data",
  };

  return values[status] ?? "Null";
};
const tooltipStyle = {
  maxWidth: '260px', 
  fontSize: '14px'
};
const SignalStrengthStatusWithDetailCard = ({
  status,
  signal,
  type,
  id,
  showDbm,
}) => {
  const renderTitleWithTooltip = (titleStr, content) => (
    <span><i style={{ backgroundColor: getColor(status) }} /><Value>{titleStr}</Value><i className="mdi mdi-help-circle ml-2 custom-target-icon2" style={{marginBottom: '10px', color: '#2065AC'}}></i><Tooltip target=".custom-target-icon2" position="top" content={content} style={tooltipStyle} /></span>
  )
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        paddingTop: "4px",
        paddingBottom: "4px",
      }}
    >
      <div>
        <Badge className="badge-dot mr-4">
          {status === 'Check Required' ? renderTitleWithTooltip('Check Required','No data stream in selected timeframe, verify device status.') :
          <>
            <i style={{ backgroundColor: getColor(status) }} />
            <Value>{status || unknown}</Value>
          </>
          }
        </Badge>
      </div>
      <div style={{ marginTop: "-10px" }}>
        <span
          className="text-muted"
          style={{ fontSize: "14px", fontWeight: "normal" }}
        >
          {getValueByStatus(status)}
        </span>
      </div>
    </div>
  );
};

SignalStrengthStatusWithDetailCard.propTypes = {
  status: PropTypes.string,
  signal: PropTypes.any,
  type: PropTypes.oneOf(["status", "index"]),
};

SignalStrengthStatusWithDetailCard.defaultProps = {
  signal: null,
  type: "status",
};

export default SignalStrengthStatusWithDetailCard;
