export function useReportOptions() {
  const columnOptionsCommon = [
    { className: "text-xs", value: "strTime", label: "Time" },
    { className: "text-xs", value: "customerName", label: "Customer Name" },
    { className: "text-xs", value: "serialNumber", label: "Serial Number" },
    { className: "text-xs", value: "imei", label: "IMEI" },
    { className: "text-xs", value: "imsi", label: "IMSI" },
  ];

  const columnOptionsUsage = [
    ...columnOptionsCommon,
    { className: "text-xs", value: "quotaLimitInHuman", label: "Quota Limit" },
    { className: "text-xs", value: "usageDescription", label: "Usage" },
  ];

  const columnOptionsSLA = [
    ...columnOptionsCommon,
    { className: "text-xs", value: "sia", label: "SIA" },
    { className: "text-xs", value: "totalUptime", label: "Uptime" },
    { className: "text-xs", value: "totalDowntime", label: "Downtime" },
    { className: "text-xs", value: "location", label: "Location" },
    { className: "text-xs", value: "area", label: "Area" },
    { className: "text-xs", value: "sla", label: "Availability" },
  ];

  const columnOptionsSignal = [
    ...columnOptionsCommon,
    { className: "text-xs", value: "location", label: "Location" },
    { className: "text-xs", value: "signalCategory", label: "Signal" },
    { className: "text-xs", value: "operator", label: "Operator" },
    { className: "text-xs", value: "area", label: "Area" },
  ];

  const columnOptionsIntegration = [
    { className: "text-xs", value: "integrationDate", label: "Datetime" },
    { className: "text-xs", value: "customer.name", label: "Customer Name" },
    { className: "text-xs", value: "type", label: "Device Type" },
    { className: "text-xs", value: "sn", label: "Serial Number" },
    { className: "text-xs", value: "sim1Imei", label: "SIM 1 IMEI" },
    { className: "text-xs", value: "sim2Imei", label: "SIM 2 IMEI" },
    { className: "text-xs", value: "sim1Imsi", label: "SIM 1 IMSI" },
    { className: "text-xs", value: "sim2Imsi", label: "SIM 2 IMSI" },
    { className: "text-xs", value: "sim1Operator", label: "SIM 1 Operator" },
    { className: "text-xs", value: "sim2Operator", label: "SIM 2 Operator" },
    { className: "text-xs", value: "integrationStatus", label: "Status" },
    { className: "text-xs", value: "location", label: "Location" },
    { className: "text-xs", value: "area", label: "Area" },
  ]

  const columnOptionsSLATunnel = [
    ...columnOptionsCommon,
    { className: "text-xs", value: "totalUptime", label: "Uptime" },
    { className: "text-xs", value: "totalDowntime", label: "Downtime" },
    { className: "text-xs", value: "sla", label: "Availability" },
    { className: "text-xs", value: "location", label: "Location" },
    { className: "text-xs", value: "area", label: "Area" },
  ];

  const mapReportTypeColumnOptions = {
    usage: columnOptionsUsage,
    sla: columnOptionsSLA,
    signal: columnOptionsSignal,
    integration: columnOptionsIntegration,
    slaTunnel: columnOptionsSLATunnel,
  }

  const reportTypeOptions = [
    { value: 'usage', label: 'Usage Report' },
    { value: 'signal', label: 'Signal Strength Report' },
    { value: 'sla', label: 'Device Availability Report' },
    { value: 'integration', label: 'Device Integration Report' },
    { value: 'slaTunnel', label: 'Tunnel Availability Report' },
  ];

  const mapReportTypeName = {
    usage: "Usage Report",
    sla: "Device Availability Report",
    signal: "Signal Strength Report",
    integration: "Device Integration Report",
    slaTunnel: "Tunnel Availability Report",
  };

  const defaultVisibleColumnsUsage = ['strTime', 'customerName', 'serialNumber', 'imsi', 'quotaLimitInHuman', 'usageDescription'];
  const defaultVisibleColumnsSLA = ['strTime', 'customerName', 'serialNumber', 'imsi', 'sia', 'totalUptime', 'sla', 'location', 'area', 'totalDowntime'];
  const defaultVisibleColumnsSignal = ['strTime', 'customerName', 'serialNumber', 'imsi', 'location', 'signalCategory', 'operator'];
  const defaultVisibleColumnsIntegration = ['integrationDate', 'customer.name', 'sn', 'type', 'sim1Imei', 'sim2Imei', 'integrationStatus'];
  const defaultVisibleColumnsSLATunnel = ['strTime', 'customerName', 'serialNumber', 'imsi', 'totalUptime', 'totalDowntime', 'sla'];

  const mapReportTypeVisibleColumns = {
    usage: defaultVisibleColumnsUsage,
    sla: defaultVisibleColumnsSLA,
    signal: defaultVisibleColumnsSignal,
    integration: defaultVisibleColumnsIntegration,
    slaTunnel: defaultVisibleColumnsSLATunnel,
  };

  const mapColumnsToReportFields = (columns) => {
    if (!columns || columns.length === 0) {
      return [];
    }
    return columns && columns.map((c) => ({ enable: true, name: c }));
  }

  const mapReportFieldsToColumns = (fields) => {
    if (!fields || fields.length === 0) {
      return [];
    }
    return fields && fields.map((f) => f.name);
  }

  const mapObjectFiltersToReportFilters = (filter) => {
    if (!filter) {
      return [];
    }
    return Object.entries(filter).map(([name, value]) => {
      return {
        name: name,
        value: Array.isArray(value) ? (value.length > 0 ? value.join(',') : null) : value
      }
    });
  }

  const defaultFilters = {
    customerIds: [],
    deviceType: [],
    serialNumber: [],
    operator: [],
    imsi: [],
    location: [],
    areaIds: [],
    availability: null,
    integrationStatus: []
  }

  const mapReportFiltersToObjectFilters = (filters) => {
    const filterObject = defaultFilters;
    if (filters) {
      filters.forEach(({ name, value }) => {
        if (['availability'].includes(name)) {
          filterObject[name] = value
        } else
        if (['customerIds', 'areaIds'].includes(name)) {
          filterObject[name] = value !== null ? value.split(',').map(v => parseInt(v)) : []
        } else {
          filterObject[name] = value !== null ? value.split(',') : []
        }
      });
    }
    return filterObject;
  }

  const availabilityOptions = [
    { value: null, label: 'All' },
    { value: 'GTE:0.9', label: '> 99.5%' },
    { value: 'LTE:0.9', label: '< 99.5%' },
  ]

  const availabilityOptionsSla = [
    { value: null, label: 'All' },
    { value: 'achieved', label: 'Achieved' },
    { value: 'not_achieved', label: 'Not Achieved' },
    { value: 'check_required', label: 'Check Required' },
  ]

  return {
    reportTypeOptions,
    mapReportTypeName,
    mapReportTypeColumnOptions,
    mapReportTypeVisibleColumns,
    defaultFilters,
    availabilityOptions,
    availabilityOptionsSla,
    mapObjectFiltersToReportFilters,
    mapReportFiltersToObjectFilters,
    mapColumnsToReportFields,
    mapReportFieldsToColumns,
  }
}