import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseApi";

export const inventoryCustomerApi = createApi({
  reducerPath: "inventoryCustomerApi",
  baseQuery: baseQuery,
  tagTypes: ["CustomerInventory"],
  endpoints: (builder) => ({
    getInventoryCustomers: builder.query({
      query: (params) => ({
        url: `/sdwan/customers`,
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "CustomerInventory",
                id,
              })),
              { type: "CustomerInventory", id: "LIST" },
            ]
          : [{ type: "CustomerInventory", id: "LIST" }],
    }),
    getInventoryCustomerDevices: builder.query({
      query: ({ customerId, ...params }) => ({
        url: `/sdwan/customers/${customerId}/device`,
        params,
      }),
    }),
  }),
});

export const {
  useGetInventoryCustomersQuery,
  useGetInventoryCustomerDevicesQuery,
} = inventoryCustomerApi;
