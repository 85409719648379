import React from "react";
import clx from "classnames";
import { Card, CardBody, CardTitle } from "reactstrap";
import { StatusBadge } from "components";
import { Tooltip } from "primereact/tooltip";
import "./style.css";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import { snakeCase } from "lodash";
import { Skeleton } from "primereact/skeleton";

const GatewayStatusCard = ({ gateways, isLoading }) => {
  const { sn } = useParams();

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Card style={{ height: 250 }}>
        <CardBody className="overflow-hidden">
          <CardTitle className="d-flex justify-content-between align-items-center">
            <h5 className="h5 mb-0">Gateway Status</h5>
            <a
              className="text-sm"
              href={`/admin/sdwan/device/${sn}/gateways/configurations`}
            >
              See all
            </a>
          </CardTitle>
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "80%" }}
          >
            <div className="d-flex flex-wrap" style={{ gap: 8 }}>
              {gateways && gateways.length > 0
                ? gateways.map((gw, i) => {
                    return (
                      <GatewayStatusItem
                        key={`gateway-item-${i}`}
                        time={gw.time}
                        gatewayName={gw.gatewayName}
                        defaultGateway={gw.defaultGateway}
                        status={gw.status}
                        rtt={gw.rtt}
                        rttd={gw.rttd}
                        loss={gw.loss}
                        text={gw.gatewayName}
                        color={
                          gw.status?.toLowerCase() === "online"
                            ? "#2DCE89"
                            : "#818EA1"
                        }
                      />
                    );
                  })
                : null}
            </div>
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ gap: 8 }}
            >
              <LegendItem text="Online" color="#2DCE89" />
              <LegendItem text="Degraded" color="#F59E0B" />
              <LegendItem text="Offline" color="#818EA1" />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

GatewayStatusCard.propTypes = {
  gateways: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      gatewayName: PropTypes.string,
      defaultGateway: PropTypes.bool,
      status: PropTypes.string,
      rtt: PropTypes.number,
      rttd: PropTypes.number,
      loss: PropTypes.number,
      interface: PropTypes.string,
    })
  ),
};

const GatewayStatusItem = ({ text, color, ...props }) => {
  return (
    <div>
      <StatusTag
        className={`gateway-tooltip-${snakeCase(props.gatewayName)} text-sm`}
        text={text}
        color={color}
      />
      <Tooltip
        showDelay={100}
        className="overview"
        target={`.gateway-tooltip-${snakeCase(props.gatewayName)}`}
        position="bottom"
        mouseTrack
        mouseTrackTop={20}
      >
        <StatusDetail {...props} />
      </Tooltip>
    </div>
  );
};

const StatusTag = ({ text, color, textColor, className }) => {
  return (
    <div
      className={clx("py-1 px-2 rounded", className)}
      style={{
        backgroundColor: color ?? "#BBBBBB",
        color: textColor ?? "#FFFFFF",
      }}
    >
      <span className="cursor-default">{text}</span>
    </div>
  );
};

const LegendItem = ({ text, color }) => {
  return (
    <div className="d-flex align-items-center" style={{ gap: 8 }}>
      <div
        style={{
          backgroundColor: color ?? "#2DCE89",
          height: "1rem",
          width: "1rem",
        }}
        className="rounded-sm"
      />
      <span className="text-sm">{text}</span>
    </div>
  );
};

const StatusDetail = ({
  gatewayName = "N/A",
  rtt = 0,
  rttd = 0,
  loss = 0,
  status = "unknown",
  time = "N/A",
}) => {
  return (
    <div className="card  border no-shadow" style={{ width: "250px" }}>
      <div className="card-body">
        <div
          className="d-flex align-items-center"
          style={{ gap: 8, marginBottom: 8 }}
        >
          <span className="text-muted text-sm">Interface:</span>
          <span className="text-muted text-sm">{gatewayName}</span>
          {status?.toLowerCase() === "online" && (
            <i className="fas fa-arrow-up text-success text-sm" />
          )}
          {status?.toLowerCase() === "offline" && (
            <i className="fas fa-arrow-down text-danger text-sm" />
          )}
        </div>
        <div className="d-flex align-items-center" style={{ color: "#525f7f" }}>
          <StatusBadge type="success" label={`RTT: ${rtt}ms`} />
        </div>
        <div className="d-flex align-items-center" style={{ color: "#525f7f" }}>
          <StatusBadge type="success" label={`RTTd: ${rttd}ms`} />
        </div>
        <div className="d-flex align-items-center" style={{ color: "#525f7f" }}>
          <StatusBadge type="warning" label={`Loss: ${loss}ms`} />
        </div>

        <div style={{ marginTop: 8 }}>
          <span className="text-muted text-sm">
            {moment(time).isValid()
              ? moment(time).format("DD/MM/YYYY HH:mm")
              : time}
          </span>
        </div>
      </div>
    </div>
  );
};

const LoadingSkeleton = () => {
  return (
    <Card style={{ height: 250 }}>
      <CardBody className="overflow-hidden">
        <CardTitle className="d-flex justify-content-between align-items-center">
          <Skeleton width="10rem" height="1.5rem" />
          <Skeleton width="5rem" height="1.5rem" />
        </CardTitle>
        <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "80%" }}
          >
            <div className="d-flex flex-wrap" style={{ gap: 8 }}>
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
              <Skeleton width="5rem" height="2rem" />
            </div>
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ gap: 8 }}
            >
              <Skeleton width="5rem" height="1.5rem" />
              <Skeleton width="5rem" height="1.5rem" />
              <Skeleton width="5rem" height="1.5rem" />
            </div>
          </div>
      </CardBody>
    </Card>
  );
};

export default GatewayStatusCard;
