import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import * as Yup from "yup";
import { Loading, TritronikInputText } from "components";
import { useCreateCustomerMutation } from "features/customers/service/customerApi";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

const CreateCustomerModal = NiceModal.create(() => {
  const [createCustomer, { isLoading }] = useCreateCustomerMutation();
  const modal = useModal();

  const onSubmit = async (customer) => {
    try {
      await createCustomer(customer).unwrap();
      toast.success(`Customer ${customer.name} created.`);
      
    } catch (error) {
      toast.error(`Error: ${error?.data?.message ?? 'Error create customer'}`);
    } finally {
      modal.resolve();
      modal.hide();
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={modal.visible}
      toggle={modal.hide}
      backdrop={isLoading}
    >
      <Formik
        enableReinitialize
        initialValues={{
          id: 0,
          name: "",
          businessId: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required("Customer name is required."),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h3 className="modal-title">Add Customer</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={modal.hide}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body py-2">
              <TritronikInputText
                label="Customer Name"
                name="name"
                placeholder="Customer Name"
                small
                required
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    block
                    color="default"
                    type="submit"
                    size="sm"
                    disabled={isLoading || !dirty}
                  >
                    Create
                    {isLoading && <Loading className="ml-2" />}
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="text-center">
                  <Button
                    block
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={modal.hide}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
});

export default CreateCustomerModal;
