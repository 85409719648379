import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import CustomerListPage from "./pages/CustomerListPage";

function CustomerInventoryRoutes() {
  return (
    <Switch>
      <Route exact path={`/admin/inventory/customers`} component={CustomerListPage} />

      <Redirect to="/admin/inventory/customers" />
    </Switch>
  );
}

export default CustomerInventoryRoutes;
