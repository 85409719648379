import { FullscreenLoading, PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import GatewayGroupForm from "../component/Form/GatewayGroupForm";
import { useParams } from "react-router";
import { useGetDeviceGatewayGroupByIdQuery } from "../services";
import { useSdwanReference } from "./../hooks/useSdwanReference";
import { useUpdateGatewayGroup } from "../hooks/useUpdateGatewayGroup";
import { toast } from "react-toastify";
import { history } from "utils";

const EditGatewayGroupConfiguration = () => {
  const { sn } = useParams();
  const { gatewayGroupId } = useParams();
  const { data, isLoading } = useGetDeviceGatewayGroupByIdQuery(
    gatewayGroupId,
    { skip: !gatewayGroupId }
  );
  const { options: poolOptions } = useSdwanReference("gw-group-pool-opts");
  const { options: triggerLevelOptions } = useSdwanReference("gw-group-trigger-level");
  const { updateGatewayGroup } = useUpdateGatewayGroup(sn)

  const title = useMemo(() => {
    if (!data) return "Gateway Group Configuration";

    return `${data.name}'s Configuration`;
  }, [data]);

  const onUpdateGwGroup = async (values) => {
    await updateGatewayGroup(values);
    toast.success("Command to update Gateway Group sent.");
    history.goBack();
  }


  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} pageTitle={sn} />
      {isLoading ? <FullscreenLoading /> : <GatewayGroupForm data={data}
        poolOptions={poolOptions}
        triggerLevelOptions={triggerLevelOptions}
        onSubmit={onUpdateGwGroup} />}
    </PageContent>
  );
};

export default EditGatewayGroupConfiguration;
