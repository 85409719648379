import { defaultOptions, usePagination } from "hooks";
import { useGetInventoryCustomersQuery } from "../services";
import { useMemo } from "react";

const defaultParams = {
  smartSearch: undefined,
};

export function useInventoryCustomerDatatable(
  params = defaultParams,
  options = defaultOptions
) {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination({
    pageNumber: 1,
    pageSize: 10,
    sortFields: options.sortFields,
    sortOrder: options.sortOrder,
  });

  const { data: paginatedResult, isLoading, ...query } = useGetInventoryCustomersQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    smartSearch:
      params.smartSearch && params.smartSearch !== ""
        ? params.smartSearch
        : undefined,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
    query
  };
}
