import { PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import { TabPanel, TabView } from "primereact/tabview";
import { Card, CardBody } from "reactstrap";
import GatewayConfigurationTabPanel from "../component/GatewayConfigurationTabPanel";
import "./styles.css";
import GatewayGroupTabPanel from "../component/GatewayGroupTabPanel";
import { useParams } from "react-router";
import { history } from "utils";

const routeMap = {
  configurations: 0,
  groups: 1,
};

const indexRouteMap = {
  0: 'configurations',
  1: 'groups'
}

const GatewayList = () => {
  const { type, sn } = useParams();

  const activeIndex = useMemo(() => {
    if (!type) return 0;
    return routeMap[type];
  }, [type]);

  const onTabChange = (index) => {
    const path = indexRouteMap[index];
    history.replace(`/admin/sdwan/device/${sn}/gateways/${path}`);
  }

  const title = useMemo(() => {
    if (!sn) return "Gateways";
    return `${sn}'s Gateways`;
  }, [sn]);

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} pageTitle={sn} />
      <Card className="tab-gateways">
        <CardBody className="p-3">
          <TabView className="tab-gateway" activeIndex={activeIndex} onTabChange={(e) => onTabChange(e.index)}>
            <TabPanel header="Configuration">
              <GatewayConfigurationTabPanel />
            </TabPanel>
            <TabPanel header="Group">
              <GatewayGroupTabPanel />
            </TabPanel>
          </TabView>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default GatewayList;
