import { PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import { TabPanel, TabView } from "primereact/tabview";
import { Card, CardBody } from "reactstrap";
import "./styles.css";
import { useParams } from "react-router";
import { history } from "utils";
import FirewallRuleTabPanel from "../component/FirewallRuleTabPanel";
import FirewallAliasesTabPanel from "../component/FirewallAliasesTabPanel";

const routeMap = {
  rules: 0,
  aliases: 1,
};

const indexRouteMap = {
  0: 'rules',
  1: 'aliases'
}

const FirewallRuleList = () => {
  const { type, sn } = useParams();

  const activeIndex = useMemo(() => {
    if (!type) return 0;
    return routeMap[type];
  }, [type]);

  const onTabChange = (index) => {
    const path = indexRouteMap[index];
    history.replace(`/admin/sdwan/device/${sn}/firewall/${path}`);
  }

  const title = useMemo(() => {
    if (!sn) return "Firewall";
    return `${sn}'s Firewall`;
  }, [sn]);

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} pageTitle={sn} />
      <Card className="tab-gateways">
        <CardBody className="p-3">
          <TabView className="tab-gateway" activeIndex={activeIndex} onTabChange={(e) => onTabChange(e.index)}>
            <TabPanel header="Rules">
              <FirewallRuleTabPanel />
            </TabPanel>
            <TabPanel header="Aliases">
              <FirewallAliasesTabPanel />
            </TabPanel>
          </TabView>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default FirewallRuleList;
